import { observable, action } from 'mobx';
import { platform } from '../../common/config';

export interface User {
  groupId: number;
  username: string;
  userId: number;
  phone: string;
  nickname: string;
  roleCode: string;
  platform: string;
  token: string;
  timingCycle: number;
  questionFlag?: boolean;
  registerSource?: number;
}

export default class UserStore {
  @observable groupId = -1;

  @observable username = '';

  @observable userId = -1;

  @observable phone = '';

  @observable nickname = '';

  @observable roleCode = '';

  @observable platform = platform;

  @observable token = '';

  @action
  addUser(user: User): void {
    this.groupId = user.groupId;
    this.username = user.username;
    this.userId = user.userId;
    this.phone = user.phone;
    this.nickname = user.nickname;
    this.roleCode = user.roleCode;
    this.token = user.token;
  }

  @action
  deleteUser(): void {
    this.groupId = -1;
    this.username = '';
    this.userId = -1;
    this.phone = '';
    this.nickname = '';
    this.roleCode = '';
    this.token = '';
  }

  @action
  updateGroupId(groupId: number): void {
    this.groupId = groupId;
  }

  @action
  updateUserName(username: string): void {
    this.username = username;
  }

  @action
  updateUserId(userId: number): void {
    this.userId = userId;
  }

  @action
  updateUserPhone(phone: string): void {
    this.phone = phone;
  }

  @action
  updateUserNickname(nickname: string): void {
    this.nickname = nickname;
  }

  @action
  updateUserRoleCode(roleCode: string): void {
    this.roleCode = roleCode;
  }

  @action
  addUserFromStorage(): void {
    this.groupId = parseInt(localStorage.getItem('groupId') || '-1');
    this.nickname = localStorage.getItem('nickname') || '';
    this.token = localStorage.getItem('token') || '';
    this.username = localStorage.getItem('username') || '';
    this.userId = parseInt(localStorage.getItem('userId') || '-1');
    this.roleCode = localStorage.getItem('roleCode') || '';
    this.phone = localStorage.getItem('phone') || '';
    // todo
    // @ts-ignore
    this.timingCycle =
      parseInt(localStorage.getItem('timingCycle') as string) || 60000;
    // @ts-ignore
    this.questionFlag = localStorage.getItem('questionFlag') === 'true';
    // @ts-ignore
    this.registerSource = localStorage.getItem('registerSource');
  }
}
