/**
 * QUE_PARSE 题目解析
 * QUE_LIKE 相似题
 * GPT_35 gpt3.5模型
 * GPT_MATH 自研数学模型
 */
export const TAB_NAME_TO_KEY = {
  QUE_PARSE: '1',
  QUE_LIKE: '2',
  GPT_35: '3',
  GPT_MATH: '4'
};

// 达到多少次数后开始追加数据
export const MAX_ADD_COUNT = 3;

export default {};

/**
 * 划选的文本类型
 * HIGH_LIGHT 手动在编辑器中标注的高亮
 * LATEX 公式
 * TABLE 表格
 * TEXT 文本
 * IMAGE 图片
 * CHILD_PART 标注的子项
 * SPACE 空格
 * NEW_LINE 换行
 *  */
export const SELECT_TYPE_LIST = {
  HIGH_LIGHT: 'highlight',
  LATEX: 'latex',
  TABLE: 'table',
  TEXT: 'text',
  IMAGE: 'image',
  CHILD_PART: 'child_part',
  SPACE: 'space',
  NEW_LINE: 'newline'
};

/**
 * 获取批注列表类型
 * PERSON 人工
 * ROBOT 机器
 * ALL 全部
 */
export const GET_ANNOTATE_LIST_TYPE = {
  PERSON: 1,
  ROBOT: 2,
  ALL: 0
};

/**
 * DRAW 划选
 * ROBOT 机器
 * CHANGE_VALUE 修改值
 */
export const ANNOTATE_CREATE_TYPE = {
  DRAW: 1,
  ROBOT: 2,
  CHANGE_VALUE: 999
};
