import axios, { AxiosInstance } from 'axios';
import { allowApiList } from './allowlist';
import { deleteUserInfoToStorage } from '../account/api/account';
import { baseURL } from './config';
import utils from './utils';

declare module 'axios' {
  export interface AxiosRequestConfig {
    disableMessage?: boolean;
    shouldThrowError?: boolean;
  }
}

interface ResponseType {
  data: any;
  code: number;
  // eslint-disable-next-line camelcase
  error_code?: number;
  message: string;
}

let disableMessage = false;
let shouldThrowError = false;

const server: AxiosInstance = axios.create({
  baseURL,
  timeout: 20000
});

server.interceptors.request.use(
  (options) => {
    const configs = { ...options };
    disableMessage =
      configs.disableMessage !== undefined ? configs.disableMessage : false;
    shouldThrowError =
      configs.shouldThrowError !== undefined ? configs.shouldThrowError : false;
    if (options.url && !allowApiList.includes(options.url)) {
      configs.headers = {
        ...options.headers,
        Authorization: localStorage.getItem('token')
      };
    }
    return configs;
  },
  (error) => Promise.reject(error)
);

server.interceptors.response.use(
  (res): any => {
    const data = res.data as ResponseType;
    if (data instanceof Blob) {
      // 兼容二进制数据
      return Promise.resolve(data);
    }
    if (data.error_code === 0) {
      // 音视频回放插件的接口返回error_code,故做此兼容
      return Promise.resolve(data);
    }
    if (/^2\d+/.test(data.code?.toString())) {
      return Promise.resolve(data);
    }
    if (data.code === 601000) {
      deleteUserInfoToStorage();
      if (window.location.pathname === '/login') return false;
      window.location.href = `/login?from=${window.location.pathname}`;
    }
    if (data.code === 601001) {
      // window.location.href = `/result/no-authority?from=${window.location.pathname}`;
    }
    // 601005, 当前账号角色已经变更，请重新登录
    if (data.code === 601005) {
      utils.messageInfo({ message: data.message });
      deleteUserInfoToStorage();
      window.location.href = '/login';
      return false;
    }
    if (!disableMessage) {
      utils.messageInfo({ message: data.message || '服务器异常，请重新尝试' });
    }
    return Promise.reject(data);
  },
  (error: any) => {
    const errorStatus = (error.request?.status || 0) as number;
    let errorMsg = '';
    if (errorStatus || errorStatus === 0) {
      switch (errorStatus) {
        case 403:
          errorMsg = '403 禁止访问页面，请查看其他页面';
          break;
        case 404:
          errorMsg = '404 没有找到相应内容，请查看其他页面';
          break;
        case 500:
          errorMsg = '500 服务器异常，请稍后重试';
          break;
        case 502:
          errorMsg = '502 请求失败，请稍后重试';
          break;
        case 0:
          errorMsg = '服务器连接超时，请重新尝试';
          break;
        default:
          errorMsg = '服务器异常，请重新尝试';
      }
    }
    if (!disableMessage) {
      utils.messageInfo({ message: errorMsg || '服务器异常，请重新尝试' });
    }
    console.error(errorMsg);
    if (shouldThrowError) {
      throw new Error(errorMsg);
    }
  }
);

export default server;
