import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import './NavLayout.less';
import renderRoutes from '../route/renderRoutes';
import { RouteType } from '../route/index';
import UserValidate from '../account/component/UserValidateComponent';

const BlankLayout: React.FC<{routes: Array<RouteType>}> = ({
  routes,
}) => {
  const [selectedRoute, setSelectedRoute] = useState<RouteType>();

  const location = useLocation();

  const history = useHistory();

  useEffect(() => {
    let currentRoute: undefined|RouteType;
    routes.forEach((routeItem) => {
      const path = routeItem.path.split(':')[0];
      if (location.pathname.includes(path)) {
        currentRoute = routeItem;
      }
    });
    if (currentRoute) {
      setSelectedRoute(currentRoute);
    }
  }, [history, location, routes]);
  return (
    <>
      <UserValidate route={selectedRoute} />
      {
        renderRoutes(routes)
      }
    </>
  );
};

export default BlankLayout;
