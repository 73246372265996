import { observable, action } from 'mobx';

export interface Team {
  name: string,
}

export default class TeamStore {
  @observable name = '';

  @action
  addTeam(team: Team): void {
    this.name = team.name;
  }

  @action
  updateTeamName(name: string): void {
    this.name = name;
  }
}
