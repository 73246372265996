import React, { lazy, Suspense } from 'react';
import BlankLayout from '../layout/BlankLayout';
import NavLayout from '../layout/NavLayout';

// eslint-disable-next-line react/require-default-props
const SuspenseComponent =
  (Component: React.FC) =>
  // eslint-disable-next-line react/require-default-props
  (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) =>
    (
      <Suspense fallback={null}>
        <Component {...props} />
      </Suspense>
    );

const LoginComponent = lazy(() => import('../account/view/Login'));
const VerifyCodeComponent = lazy(() => import('../account/view/Verification'));
const RegisterComponent = lazy(() => import('../account/view/Register'));
const RegsterResultComponent = lazy(
  () => import('../result/view/RegisterSuccess')
);

const MarkList = lazy(() => import('../annotation/view/MarkList'));

const Mark = lazy(() => import('../annotation/view/Mark'));
const Review = lazy(() => import('../annotation/view/Review'));
const Modify = lazy(() => import('../annotation/view/Modify'));
const Inspect = lazy(() => import('../annotation/view/Inspect'));
const Check = lazy(() => import('../annotation/view/Check'));

const MarkAudioCut = lazy(() => import('../annotation/view/MarkAudioCut'));
const ReviewAudioCut = lazy(() => import('../annotation/view/ReviewAudioCut'));
const ModifyAudioCut = lazy(() => import('../annotation/view/ModifyAudioCut'));
const InspectAudioCut = lazy(
  () => import('../annotation/view/InspectAudioCut')
);
const CheckAudioCut = lazy(() => import('../annotation/view/CheckAudioCut'));

const MarkAudioCharLevelCut = lazy(
  () => import('../annotation/view/MarkAudioCharLevelCut')
);
const ReviewAudioCharLevelCut = lazy(
  () => import('../annotation/view/ReviewAudioCharLevelCut')
);
const ModifyAudioCharLevelCut = lazy(
  () => import('../annotation/view/ModifyAudioCharLevelCut')
);
const InspectAudioCharLevelCut = lazy(
  () => import('../annotation/view/InspectAudioCharLevelCut')
);
const CheckAudioCharLevelCut = lazy(
  () => import('../annotation/view/CheckAudioCharLevelCut')
);

const MarkAudioTrans = lazy(() => import('../annotation/view/MarkAudioTrans'));
const InspectAudioTrans = lazy(
  () => import('../annotation/view/InspectAudioTrans')
);
const ReviewAudioTrans = lazy(
  () => import('../annotation/view/ReviewAudioTrans')
);
const CheckAudioTrans = lazy(
  () => import('../annotation/view/CheckAudioTrans')
);
const ModifyAudioTrans = lazy(
  () => import('../annotation/view/ModifyAudioTrans')
);

const MarkImageGlobal = lazy(
  () => import('../annotation/view/MarkImageGlobal')
);
const InspectImageGlobal = lazy(
  () => import('../annotation/view/InspectImageGlobal')
);
const ReviewImageGlobal = lazy(
  () => import('../annotation/view/ReviewImageGlobal')
);
const CheckImageGlobal = lazy(
  () => import('../annotation/view/CheckImageGlobal')
);
const ModifyImageGlobal = lazy(
  () => import('../annotation/view/ModifyImageGlobal')
);

// 标注
const MarkVideo = lazy(() => import('../annotation/view/MarkVideo'));
// 质检
const InspectVideo = lazy(() => import('../annotation/view/InspectVideo'));
// 预览
const ReviewVideo = lazy(() => import('../annotation/view/ReviewVideo'));
// 检查
const CheckVideo = lazy(() => import('../annotation/view/CheckVideo'));
// 修改
const ModifyVideo = lazy(() => import('../annotation/view/ModifyVideo'));

const MarkABX = lazy(() => import('../annotation/view/MarkABX'));
const ReviewABX = lazy(() => import('../annotation/view/ReviewABX'));

const MarkVideoAudioCut = lazy(
  () => import('../annotation/view/MarkVideoAudioCut')
);
const InspectVideoAudioCut = lazy(
  () => import('../annotation/view/InspectVideoAudioCut')
);
const ReviewVideoAudioCut = lazy(
  () => import('../annotation/view/ReviewVideoAudioCut')
);
const CheckVideoAudioCut = lazy(
  () => import('../annotation/view/CheckVideoAudioCut')
);
const ModifyVideoAudioCut = lazy(
  () => import('../annotation/view/ModifyVideoAudioCut')
);

const TemplateType = lazy(() => import('../template/view/TemplateType'));
const TemplateInfoList = lazy(
  () => import('../template/view/TemplateInfoList')
);

const NoData = lazy(() => import('../result/view/NoData'));
const NoMatch = lazy(() => import('../result/view/NoMatch'));
const NoAuthority = lazy(() => import('../result/view/NoAuthority'));

const workFlowManger = lazy(
  () => import('../annotation/view/Workflow/WorkflowManger')
);
const workFlowInfo = lazy(
  () => import('../annotation/view/Workflow/workFlowInfo')
);
const ProjectList = lazy(() => import('../project/view/List'));
const ProjectDetail = lazy(() => import('../project/view/Detail'));
const ProjectDetailTab = lazy(() => import('../project/view/ProjectDetailTab'));
const AllotTeam = lazy(() => import('../project/view/AllotTeamList'));
const TabPaneTemplate = lazy(() => import('../project/view/TabsTemplate'));
const QuestionList = lazy(() => import('../project/view/QuestionList'));
const templatePrice = lazy(() => import('../project/view/templatePrice'));
const DataSet = lazy(() => import('../project/view/DataSet'));
const AdminMenberManage = lazy(
  () => import('../project/view/AdminMenberManage')
);
const Management = lazy(() => import('../project/view/Management'));
const LatexConverter = lazy(() => import('../project/component/Converter'));
const TemplateLibrary = lazy(() => import('../project/view/TemplateLibrary'));

const TaskList = lazy(() => import('../task/view/List'));
const TaskDetail = lazy(() => import('../task/view/Detail'));
const CustomInspectCheck = lazy(
  () => import('../annotation/view/CustomInspectCheck')
);
const AudioABXMarkAdminTaskList = lazy(
  () => import('../task/view/ABXMarkAdminList')
);

const ProjectStatisticsList = lazy(
  () => import('../statistics/view/ProjectStatisticsList')
);
const TaskStatisticsList = lazy(
  () => import('../statistics/view/TaskStatisticsList')
);
const TaskUserStatisticsList = lazy(
  () => import('../statistics/view/TaskUserStatisticsList')
);
const TaskUserStatisticsOtherMarkList = lazy(
  () => import('../statistics/view/TaskUserStatisticsOtherMarkList')
);
const TaskUserStatisticsCutList = lazy(
  () => import('../statistics/view/TaskUserStatisticsCutList')
);
const TaskUserStatisticsTransList = lazy(
  () => import('../statistics/view/TaskUserStatisticsTransList')
);
const TaskUserStatisticsImageGlobalList = lazy(
  () => import('../statistics/view/TaskUserStatisticsImageGlobalList')
);
const TaskUserStatisticsVideoMarkList = lazy(
  () => import('../statistics/view/TaskUserStatisticsVideoMarkList')
);
const TaskUserStatisticsABXList = lazy(
  () => import('../statistics/view/TaskUserStatisticsABXList')
);
const TaskUserStatisticsVideoAudioCutList = lazy(
  () => import('../statistics/view/TaskUserStatisticsVideoAudioCutList')
);
const TaskUserStatisticsAudioCharLevelCutList = lazy(
  () => import('../statistics/view/TaskUserStatisticsAudioCharLevelCutList')
);
const MemberManage = lazy(() => import('../personnel/view/MemberManage'));
const PersonnelInfo = lazy(() => import('../personnel/view/PersonnelInfo'));
const TagsManage = lazy(() => import('../personnel/view/TagsManage'));
const TeamManage = lazy(() => import('../personnel/view/TeamManage'));
const ManpowerAnalysis = lazy(
  () => import('../personnel/view/ManpowerAnalysis')
);

const SurveySatisfaction = lazy(
  () => import('../questionnaire/view/SurveySatisfaction')
);
const SurveyResult = lazy(() => import('../questionnaire/view/SurveyResult'));

const MarkSpeechSliceDemo = lazy(
  () => import('../annotation/component/audio-visualization/example')
);

const OtherMarkQuestionTalk = lazy(
  () => import('../annotation/view/OtherMarkQueTalk/OtherMarkQueTalk')
);

const OtherMarkLowCodeTemplate = lazy(
  () =>
    import(
      '../annotation/view/OtherMarkLowCodeTemplate/OtherMarkLowCodeTemplate'
    )
);
const OtherInspectLowCodeTemplate = lazy(
  () =>
    import(
      '../annotation/view/OtherMarkLowCodeTemplate/OtherInspectLowCodeTemplate'
    )
);
const OtherModifyLowCodeTemplate = lazy(
  () =>
    import(
      '../annotation/view/OtherMarkLowCodeTemplate/OtherModifyLowCodeTemplate'
    )
);
const OtherPreviewLowCodeTemplate = lazy(
  () =>
    import(
      '../annotation/view/OtherMarkLowCodeTemplate/OtherPreviewLowCodeTemplate'
    )
);

const OtherMarkMultiRoundOnline = lazy(
  () =>
    import(
      '../annotation/view/OtherMarkMultiRoundOnline/OtherMarkMultiRoundOnline'
    )
);
const OtherInspectMultiRoundOnline = lazy(
  () =>
    import(
      '../annotation/view/OtherMarkMultiRoundOnline/OtherInspectMultiRoundOnline'
    )
);
const OtherModifyMultiRoundOnline = lazy(
  () =>
    import(
      '../annotation/view/OtherMarkMultiRoundOnline/OtherModifyMultiRoundOnline'
    )
);
const OtherPreviewMultiRoundOnline = lazy(
  () =>
    import(
      '../annotation/view/OtherMarkMultiRoundOnline/OtherPreviewMultiRoundOnline'
    )
);

// 去修改对话重写
const OhterModifyQueTalk = lazy(
  () => import('../annotation/view/OtherMarkQueTalk/OhterModifyQueTalk')
);

// 去修改对话排序
const OhterModifyQueSort = lazy(
  () => import('../annotation/view/OtherMarkQueSort/OhterModifyQueSort')
);

// 去修改对话比较-离线
const OhterModifyCompare = lazy(
  () => import('../annotation/view/OtherMarkCompare/OhterModifyCompare')
);

const OtherMarkQuestionSort = lazy(
  () => import('../annotation/view/OtherMarkQueSort/OtherMarkQueSort')
);
const OtherMarkQuestionInput = lazy(
  () => import('../annotation/view/OtherMarkQueInput/OtherMarkQueInput')
);
const OtherMarkQuestionEnter = lazy(
  () => import('../annotation/view/OtherMarkQueInput/OtherMarkQueEnter')
);
const OtherPreViewQueTalk = lazy(
  () => import('../annotation/view/OtherMarkQueTalk/OtherPreViewQueTalk')
);
const DatasetPreViewQueTalk = lazy(
  () => import('../annotation/view/OtherMarkQueTalk/DatasetPreView')
);

const OtherPreViewQueSort = lazy(
  () => import('../annotation/view/OtherMarkQueSort/OtherPreViewQueSort')
);

const OtherMarkCompare = lazy(
  () => import('../annotation/view/OtherMarkCompare/OtherMarkCompare')
);

const OtherPreViewCompare = lazy(
  () => import('../annotation/view/OtherMarkCompare/OtherPreViewCompare')
);

const OtherMarkCompareOnline = lazy(
  () =>
    import('../annotation/view/OtherMarkCompareOnline/OtherMarkCompareOnline')
);

const InspectConversationRewrite = lazy(
  () => import('../annotation/view/OtherMarkQueTalk/OtherInspectQueTalk')
);

const InspectConversationSort = lazy(
  () => import('@/annotation/view/OtherMarkQueSort/OtherInspectQueSort')
);

const OhterModifyTestCut = lazy(
  () => import('@/annotation/view/OtherMarkTestInput/OhterModifyTestCut')
);

const OhterModifyTestInput = lazy(
  () => import('@/annotation/view/OtherMarkTestInput/OhterModifyTestInput')
);

const OtherInspectTestInput = lazy(
  () => import('@/annotation/view/OtherMarkTestInput/OtherInspectTestInput')
);

const OtherMarkTestCut = lazy(
  () => import('@/annotation/view/OtherMarkTestInput/OtherMarkTestCut')
);

const OtherMarkTestInput = lazy(
  () => import('@/annotation/view/OtherMarkTestInput/OtherMarkTestInput')
);

const OtherPreViewTestInput = lazy(
  () => import('@/annotation/view/OtherMarkTestInput/OtherPreViewTestInput')
);

const OtherMarkXXB = lazy(
  () => import('@/annotation/view/OtherMarkXueXiaoBan/OtherMarkXXB')
);

const OtherModifyXXB = lazy(
  () => import('@/annotation/view/OtherMarkXueXiaoBan/OtherModifyXXB')
);

const OtherInspectXXB = lazy(
  () => import('@/annotation/view/OtherMarkXueXiaoBan/OtherInspectXXB')
);

const OtherPreViewXXB = lazy(
  () => import('@/annotation/view/OtherMarkXueXiaoBan/OtherPreViewXXB')
);

const CleanedData = lazy(() => import('@/result/view/CleanedData'));

const InspectLog = lazy(() => import('@/download/view/InspectLog'));
const Derived = lazy(() => import('@/download/view/Derived'));
const TroubleshootPage = lazy(
  () => import('@/annotation/view/OtherMarkQueTalk/TroubleshootPage')
);

const LowCode = lazy(() => import('@/low_code/view/LowCode'));
const LowCodePreview = lazy(() => import('@/low_code/view/LowCodePreview'));
// 试题查询
const QuestionBank = lazy(
  () => import('@/annotation/view/QuestionBank/QuestionBank')
);

const DataRetrieval = lazy(() => import('@/project/view/DataRetrieval'));

export interface RouteType {
  key?: string;
  path: string;
  name?: string;
  exact?: boolean;
  authority?: Array<string>;
  crumbs?: Array<string>;
  strict?: boolean;
  requiresAuth?: boolean;
  component: React.FC<{ routes: any }>;
  routes?: Array<RouteType>;
  redirect?: string;
}

const routes: Array<RouteType> = [
  {
    path: '/login',
    exact: true,
    component: BlankLayout,
    routes: [
      {
        path: '/login',
        name: 'login',
        exact: true,
        component: SuspenseComponent(LoginComponent)
      }
    ]
  },
  {
    path: '/login/verification',
    exact: true,
    component: BlankLayout,
    routes: [
      {
        path: '/login/verification',
        name: 'verification',
        exact: true,
        component: SuspenseComponent(VerifyCodeComponent)
      }
    ]
  },
  {
    path: '/register',
    component: BlankLayout,
    routes: [
      {
        path: '/register',
        name: 'register',
        exact: true,
        component: SuspenseComponent(RegisterComponent)
      }
    ]
  },
  {
    path: '/troubleshoot-page',
    component: BlankLayout,
    routes: [
      {
        path: '/troubleshoot-page/:markType/:subMarkType/:taskId/:dataId?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(TroubleshootPage)
      }
    ]
  },
  {
    path: '/result',
    component: BlankLayout,
    routes: [
      {
        path: '/result/no-data/:message',
        exact: true,
        component: SuspenseComponent(NoData)
      },
      {
        path: '/result/no-authority',
        exact: true,
        component: SuspenseComponent(NoAuthority)
      },
      {
        path: '/result/no-match',
        exact: true,
        component: SuspenseComponent(NoMatch)
      },
      {
        path: '/result/register/:roleType?/:groupCode?',
        exact: true,
        component: SuspenseComponent(RegsterResultComponent)
      }
    ]
  },
  {
    path: '/annotation-detail',
    component: BlankLayout,
    routes: [
      {
        path: '/annotation-detail/mark/:taskId/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'OPERATION_COMMON_NORMAL'],
        component: SuspenseComponent(Mark)
      },
      {
        path: '/annotation-detail/review/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'OPERATION_COMMON_NORMAL',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(Review)
      },
      {
        path: '/annotation-detail/modify/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION_COMMON_NORMAL'
        ],
        component: SuspenseComponent(Modify)
      },
      {
        path: '/annotation-detail/inspect/:taskId/:userId/:inspectType/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'OPERATION_COMMON_NORMAL'],
        component: SuspenseComponent(Inspect)
      },
      {
        path: '/annotation-detail/check/:taskId/:checkPercent/:markType',
        exact: true,
        authority: ['ALGORITHM'],
        component: SuspenseComponent(Check)
      },
      {
        path: '/annotation-detail/mark-audio-cut/:taskId/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(MarkAudioCut)
      },
      {
        path: '/annotation-detail/review-audio-cut/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'OPERATION_COMMON_NORMAL',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(ReviewAudioCut)
      },
      {
        path: '/annotation-detail/modify-audio-cut/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION_COMMON_NORMAL'
        ],
        component: SuspenseComponent(ModifyAudioCut)
      },
      {
        path: '/annotation-detail/inspect-audio-cut/:taskId/:userId/:inspectType/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(InspectAudioCut)
      },
      {
        path: '/annotation-detail/inspect-conversation-rewrite/:taskId/:userId/:inspectType/:markType/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(InspectConversationRewrite)
      },
      {
        path: '/annotation-detail/low-code-template/:stageType/:taskId/:userId/:inspectType/:markType/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherInspectLowCodeTemplate)
      },
      {
        path: '/annotation-detail/inspect-multiRound-online/:taskId/:userId/:inspectType/:markType/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherInspectMultiRoundOnline)
      },
      {
        path: '/annotation-detail/inspect-conversation-sort/:stageType/:taskId/:userId/:inspectType/:markType/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(InspectConversationSort)
      },
      {
        path: '/annotation-detail/check-audio-cut/:taskId/:checkPercent/:markType',
        exact: true,
        authority: ['ALGORITHM'],
        component: SuspenseComponent(CheckAudioCut)
      },
      {
        path: '/annotation-detail/mark-audio-char-level-cut/:taskId/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(MarkAudioCharLevelCut)
      },
      {
        path: '/annotation-detail/review-audio-char-level-cut/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'OPERATION_COMMON_NORMAL',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(ReviewAudioCharLevelCut)
      },
      {
        path: '/annotation-detail/modify-audio-char-level-cut/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(ModifyAudioCharLevelCut)
      },
      {
        path: '/annotation-detail/inspect-audio-char-level-cut/:taskId/:userId/:inspectType/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(InspectAudioCharLevelCut)
      },
      {
        path: '/annotation-detail/check-audio-char-level-cut/:taskId/:checkPercent/:markType',
        exact: true,
        authority: ['ALGORITHM'],
        component: SuspenseComponent(CheckAudioCharLevelCut)
      },
      {
        path: '/annotation-detail/mark-audio-trans/:taskId/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(MarkAudioTrans)
      },
      {
        path: '/annotation-detail/inspect-audio-trans/:taskId/:userId/:inspectType/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(InspectAudioTrans)
      },
      {
        path: '/annotation-detail/review-audio-trans/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'OPERATION_COMMON_NORMAL',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(ReviewAudioTrans)
      },
      {
        path: '/annotation-detail/check-audio-trans/:taskId/:checkPercent/:markType',
        exact: true,
        authority: ['ALGORITHM'],
        component: SuspenseComponent(CheckAudioTrans)
      },
      {
        path: '/annotation-detail/modify-audio-trans/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(ModifyAudioTrans)
      },
      {
        path: '/annotation-detail/mark-image-global/:taskId/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(MarkImageGlobal)
      },
      {
        path: '/annotation-detail/inspect-image-global/:taskId/:userId/:inspectType/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(InspectImageGlobal)
      },
      {
        path: '/annotation-detail/review-image-global/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON_NORMAL',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(ReviewImageGlobal)
      },
      {
        path: '/annotation-detail/check-image-global/:taskId/:checkPercent/:markType',
        exact: true,
        authority: ['ALGORITHM'],
        component: SuspenseComponent(CheckImageGlobal)
      },
      {
        path: '/annotation-detail/modify-image-global/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(ModifyImageGlobal)
      },
      // 新增视频标注
      {
        path: '/annotation-detail/mark-video/:taskId/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(MarkVideo)
      },
      // 新增视频质检
      {
        path: '/annotation-detail/inspect-video/:taskId/:userId/:inspectType/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(InspectVideo)
      },
      // 新增视频预览
      {
        path: '/annotation-detail/review-video/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(ReviewVideo)
      },
      // 检查
      {
        path: '/annotation-detail/check-video/:taskId/:checkPercent/:markType',
        exact: true,
        authority: ['ALGORITHM'],
        component: SuspenseComponent(CheckVideo)
      },
      // 修改
      {
        path: '/annotation-detail/modify-video/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(ModifyVideo)
      },
      {
        path: '/annotation-detail/mark-abx/:taskId/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(MarkABX)
      },
      {
        path: '/annotation-detail/review-abx/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(ReviewABX)
      },
      {
        path: '/annotation-detail/mark-video-audio-cut/:taskId/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(MarkVideoAudioCut)
      },
      {
        path: '/annotation-detail/inspect-video-audio-cut/:taskId/:userId/:inspectType/:markType',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(InspectVideoAudioCut)
      },
      {
        path: '/annotation-detail/review-video-audio-cut/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(ReviewVideoAudioCut)
      },
      {
        path: '/annotation-detail/check-video-audio-cut/:taskId/:checkPercent/:markType',
        exact: true,
        authority: ['ALGORITHM'],
        component: SuspenseComponent(CheckVideoAudioCut)
      },
      {
        path: '/annotation-detail/modify-video-audio-cut/:taskId/:stageType/:taskStatus/:markType/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(ModifyVideoAudioCut)
      },
      {
        path: '/annotation-detail/question-input/:taskId/:userId/:markType/:inspectType/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherMarkQuestionEnter)
      },
      {
        path: '/annotation-detail/test-enter/:taskId/:userId/:markType/:inspectType/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherInspectTestInput)
      },
      {
        path: '/annotation-detail/auto-add-test/:taskId/:userId/:markType/:inspectType/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherInspectXXB)
      }
    ]
  },
  // 新增标注任务
  {
    path: '/other-mark',
    component: BlankLayout,
    routes: [
      {
        path: '/other-mark/conversation-rewrite/:taskId/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherMarkQuestionTalk)
      },
      // 低代码标注页面
      {
        path: '/other-mark/low-code-template/:taskId/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherMarkLowCodeTemplate)
      },
      {
        path: '/other-mark/multiRound-online/:taskId/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherMarkMultiRoundOnline)
      },
      {
        path: '/other-mark/conversation-sort/:taskId/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherMarkQuestionSort)
      },
      {
        path: '/other-mark/question-input/:taskId/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherMarkQuestionInput)
      },
      {
        path: '/other-mark/question-enter/:taskId/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherMarkQuestionEnter)
      },
      {
        path: '/other-mark/conversation-compare/:taskId/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherMarkCompare)
      },
      {
        path: '/other-mark/conversation-compare-online/:taskId/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherMarkCompareOnline)
      },
      {
        path: '/other-mark/test-input/:taskId/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherMarkTestCut)
      },
      {
        path: '/other-mark/test-enter/:taskId/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherMarkTestInput)
      },
      {
        path: '/other-mark/auto-add-test/:taskId/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(OtherMarkXXB)
      }
    ]
  },
  // 修改任务
  {
    path: '/other-modify',
    component: BlankLayout,
    routes: [
      {
        path: '/other-modify/conversation-rewrite/:taskId/:stageType/:taskStatus/:markType/:subMarkType?/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(OhterModifyQueTalk)
      },
      {
        path: '/other-modify/low-code-template/:taskId/:stageType/:taskStatus/:markType/:subMarkType?/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(OtherModifyLowCodeTemplate)
      },
      {
        path: '/other-modify/multiRound-online/:taskId/:stageType/:taskStatus/:markType/:subMarkType?/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(OtherModifyMultiRoundOnline)
      },
      {
        path: '/other-modify/conversation-sort/:taskId/:stageType/:taskStatus/:markType/:subMarkType?/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(OhterModifyQueSort)
      },
      {
        path: '/other-modify/question-input/:taskId/:stageType/:taskStatus/:markType/:subMarkType?/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(OtherMarkQuestionInput)
      },
      {
        path: '/other-modify/question-enter/:taskId/:stageType/:taskStatus/:markType/:subMarkType?/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(OtherMarkQuestionEnter)
      },
      {
        path: '/other-modify/conversation-compare/:taskId/:stageType/:taskStatus/:markType/:subMarkType?/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(OhterModifyCompare)
      },
      {
        path: '/other-modify/test-input/:taskId/:stageType/:taskStatus/:markType/:subMarkType?/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(OhterModifyTestCut)
      },
      {
        path: '/other-modify/test-enter/:taskId/:stageType/:taskStatus/:markType/:subMarkType?/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(OhterModifyTestInput)
      },
      {
        path: '/other-modify/auto-add-test/:taskId/:stageType/:taskStatus/:markType/:subMarkType?/:dataId?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT', 'ALGORITHM'],
        component: SuspenseComponent(OtherModifyXXB)
      }
    ]
  },
  // 新增预览任务
  {
    path: '/other-pre-view',
    component: BlankLayout,
    routes: [
      {
        path: '/other-pre-view/conversation-rewrite/:stageType/:taskId/:subMarkType?/:percent?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'OPERATION_COMMON_NORMAL',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(OtherPreViewQueTalk)
      },
      {
        path: '/other-pre-view/multiRound-online/:stageType/:taskId/:subMarkType?/:percent?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'OPERATION_COMMON_NORMAL',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(OtherPreviewMultiRoundOnline)
      },
      {
        path: '/other-pre-view/low-code-template/:stageType/:taskId/:subMarkType?/:percent?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON',
          'OPERATION_COMMON_NORMAL'
        ],
        component: SuspenseComponent(OtherPreviewLowCodeTemplate)
      },
      {
        path: '/other-pre-view/conversation-rewrite-pre/:id',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'OPERATION_COMMON_NORMAL',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(DatasetPreViewQueTalk)
      },
      // DatasetPreViewQueTalk
      {
        path: '/other-pre-view/conversation-sort/:stageType/:taskId/:subMarkType?/:percent?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION_COMMON_NORMAL',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(OtherPreViewQueSort)
      },
      {
        path: '/other-pre-view/question-input/:stageType/:taskId/:subMarkType?/:percent?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'OPERATION_COMMON_NORMAL',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(OtherMarkQuestionEnter)
      },
      {
        path: '/other-pre-view/conversation-compare/:stageType/:taskId/:subMarkType?/:percent?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION_COMMON_NORMAL',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(OtherPreViewCompare)
      },
      {
        path: '/other-pre-view/test-enter/:stageType/:taskId/:subMarkType?/:percent?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(OtherPreViewTestInput)
      },
      {
        path: '/other-pre-view/auto-add-test/:stageType/:taskId/:subMarkType?/:percent?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION_COMMON_NORMAL',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(OtherPreViewXXB)
      }
    ]
  },
  // 新增下载中心
  {
    path: '/download',
    component: NavLayout,
    routes: [
      {
        path: '/download/inspect-log',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        crumbs: ['下载中心', '质检记录'],
        component: SuspenseComponent(InspectLog)
      },
      {
        path: '/download/derived-data',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        crumbs: ['下载中心', '导出数据'],
        component: SuspenseComponent(Derived)
      }
    ]
  },
  {
    path: '/annotation',
    component: NavLayout,
    routes: [
      {
        path: '/annotation/mark-list',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        crumbs: ['我的任务', '标注任务'],
        component: SuspenseComponent(MarkList)
      },
      {
        path: '/annotation/inspect-list',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        crumbs: ['我的任务', '质检任务'],
        component: SuspenseComponent(TaskList)
      },
      {
        path: '/annotation/inspect-list/detail/:taskId/:status/:markType/:subMarkType?',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        component: SuspenseComponent(TaskDetail)
      },
      // 自定义质检、自定义验收，质检员，团队管理员和验收员可查看
      {
        path: '/annotation/custom-inspect-check/detail/:taskId/:status/:markType/:subMarkType?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'ALGORITHM'
        ],
        component: SuspenseComponent(CustomInspectCheck)
      }
    ]
  },
  {
    path: '/task',
    component: NavLayout,
    routes: [
      {
        path: '/task/list/:projectId/:markType/:subMarkType?',
        exact: true,
        authority: [
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'ALGORITHM',
          'OPERATION_COMMON',
          'OPERATION_COMMON_NORMAL'
        ],
        crumbs: ['业务管理', '项目管理'],
        component: SuspenseComponent(TaskList)
      },
      {
        path: '/task/detail/:taskId/:status/:markType',
        exact: true,
        authority: [
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON',
          'OPERATION_COMMON_NORMAL'
        ],
        component: SuspenseComponent(TaskDetail)
      },
      {
        path: '/task/list-abx/:projectId/:markType',
        exact: true,
        authority: [
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'ALGORITHM',
          'OPERATION_COMMON',
          'OPERATION_COMMON_NORMAL'
        ],
        crumbs: ['业务管理', '项目管理'],
        component: SuspenseComponent(AudioABXMarkAdminTaskList)
      }
    ]
  },
  {
    path: '/project',
    component: NavLayout,
    routes: [
      {
        path: '/project/list',
        exact: true,
        authority: [
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON_NORMAL',
          'ALGORITHM',
          'OPERATION_COMMON'
        ],
        crumbs: ['业务管理', '项目管理'],
        component: SuspenseComponent(ProjectList)
      },
      {
        path: '/project/workFlow',
        exact: true,
        authority: [
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON_NORMAL',
          'ALGORITHM',
          'OPERATION_COMMON'
        ],
        crumbs: ['业务管理', '工作流管理'],
        component: SuspenseComponent(workFlowManger)
      },
      {
        path: '/project/workFlow/:workFlowId',
        exact: true,
        authority: [
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON_NORMAL',
          'ALGORITHM',
          'OPERATION_COMMON'
        ],
        crumbs: ['业务管理', '工作流管理'],
        component: SuspenseComponent(workFlowInfo)
      },
      {
        path: '/project/detail/:projectId',
        exact: true,
        authority: [
          'OPERATION',
          'ALGORITHM',
          'OPERATION_COMMON',
          'OPERATION_COMMON_NORMAL'
        ],
        crumbs: ['业务管理', '项目管理'],
        component: SuspenseComponent(ProjectDetail)
      },
      {
        path: '/project/detail-tab/:projectId/:markType/:subMarkType/:projectStatus/:customTemplateId',
        exact: true,
        authority: [
          'OPERATION',
          'ALGORITHM',
          'OPERATION_COMMON',
          'OPERATION_COMMON_NORMAL'
        ],
        crumbs: ['业务管理', '项目管理'],
        component: SuspenseComponent(ProjectDetailTab)
      },
      {
        path: '/project/allot-team/:projectId',
        exact: true,
        authority: ['OPERATION', 'OPERATION_COMMON', 'OPERATION_COMMON_NORMAL'],
        crumbs: ['业务管理', '项目管理'],
        component: SuspenseComponent(AllotTeam)
      },
      {
        path: '/project/info/:templateId',
        exact: true,
        authority: ['OPERATION', 'OPERATION_COMMON', 'OPERATION_COMMON_NORMAL'],
        crumbs: ['业务管理', '项目管理'],
        component: SuspenseComponent(TabPaneTemplate)
      },
      {
        path: '/project/upload-data/:templateId',
        exact: true,
        authority: ['OPERATION', 'OPERATION_COMMON', 'OPERATION_COMMON_NORMAL'],
        crumbs: ['业务管理', '项目管理'],
        component: SuspenseComponent(TabPaneTemplate)
      },
      {
        path: '/project/config-mark-info/:templateId',
        exact: true,
        authority: ['OPERATION', 'OPERATION_COMMON', 'OPERATION_COMMON_NORMAL'],
        crumbs: ['业务管理', '项目管理'],
        component: SuspenseComponent(TabPaneTemplate)
      },
      {
        path: '/project/process-and-allot-task/:templateId',
        exact: true,
        authority: ['OPERATION', 'OPERATION_COMMON'],
        crumbs: ['业务管理', '项目管理', 'OPERATION_COMMON_NORMAL'],
        component: SuspenseComponent(TabPaneTemplate)
      }
    ]
  },
  {
    path: '/template',
    component: NavLayout,
    routes: [
      {
        path: '/template/type',
        exact: true,
        authority: ['OPERATION', 'OPERATION_COMMON', 'OPERATION_COMMON_NORMAL'],
        crumbs: ['业务管理', '项目管理'],
        component: SuspenseComponent(TemplateType)
      },
      {
        path: '/template/type/:status',
        exact: true,
        authority: ['OPERATION', 'OPERATION_COMMON', 'OPERATION_COMMON_NORMAL'],
        crumbs: ['业务管理', '项目管理'],
        component: SuspenseComponent(TemplateType)
      },
      {
        path: '/template/list/:markType',
        exact: true,
        authority: ['OPERATION', 'OPERATION_COMMON', 'OPERATION_COMMON_NORMAL'],
        crumbs: ['业务管理', '项目管理'],
        component: SuspenseComponent(TemplateInfoList)
      }
    ]
  },
  {
    path: '/statistic',
    component: NavLayout,
    routes: [
      {
        path: '/statistic/project',
        exact: true,
        authority: [
          'OPERATION',
          'ALGORITHM',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        crumbs: ['业务管理', '数据统计'],
        component: SuspenseComponent(ProjectStatisticsList)
      },
      {
        path: '/statistic/project/:projectId/:markType',
        exact: true,
        authority: [
          'OPERATION',
          'ALGORITHM',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        crumbs: ['业务管理', '数据统计'],
        component: SuspenseComponent(TaskStatisticsList)
      },
      {
        path: '/statistic/task/:taskId',
        exact: true,
        authority: [
          'OPERATION',
          'ALGORITHM',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        crumbs: ['业务管理', '数据统计'],
        component: SuspenseComponent(TaskUserStatisticsList)
      },
      {
        path: '/statistic/task/cut/:taskId/:projectId/:textAreaTitle',
        exact: true,
        authority: [
          'OPERATION',
          'ALGORITHM',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        crumbs: ['业务管理', '数据统计'],
        component: SuspenseComponent(TaskUserStatisticsCutList)
      },
      {
        path: '/statistic/task/trans/:taskId/:projectId/:textAreaTitle',
        exact: true,
        authority: [
          'OPERATION',
          'ALGORITHM',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        crumbs: ['业务管理', '数据统计'],
        component: SuspenseComponent(TaskUserStatisticsTransList)
      },
      {
        path: '/statistic/task/imageglobal/:taskId/:textAreaTitle?',
        exact: true,
        authority: [
          'OPERATION',
          'ALGORITHM',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        crumbs: ['业务管理', '数据统计'],
        component: SuspenseComponent(TaskUserStatisticsImageGlobalList)
      },
      {
        path: '/statistic/task/videomark/:taskId/:textAreaTitle?',
        exact: true,
        authority: [
          'OPERATION',
          'ALGORITHM',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        crumbs: ['业务管理', '数据统计'],
        component: SuspenseComponent(TaskUserStatisticsVideoMarkList)
      },
      {
        path: '/statistic/task/abx/:taskId',
        exact: true,
        authority: [
          'OPERATION',
          'ALGORITHM',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        crumbs: ['业务管理', '数据统计'],
        component: SuspenseComponent(TaskUserStatisticsABXList)
      },
      {
        path: '/statistic/task/videoaudiocut/:taskId/:projectId/:textAreaTitle?',
        exact: true,
        authority: [
          'OPERATION',
          'ALGORITHM',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        crumbs: ['业务管理', '数据统计'],
        component: SuspenseComponent(TaskUserStatisticsVideoAudioCutList)
      },
      {
        path: '/statistic/task/audiocharlevelcut/:taskId/:projectId',
        exact: true,
        authority: [
          'OPERATION',
          'ALGORITHM',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        crumbs: ['业务管理', '数据统计'],
        component: SuspenseComponent(TaskUserStatisticsAudioCharLevelCutList)
      },
      {
        path: '/statistic/task/otherMark/:taskId',
        exact: true,
        authority: [
          'OPERATION',
          'ALGORITHM',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        crumbs: ['业务管理', '数据统计'],
        component: SuspenseComponent(TaskUserStatisticsOtherMarkList)
      }
    ]
  },
  {
    path: '/price',
    component: NavLayout,
    routes: [
      {
        path: '/price/template',
        exact: true,
        authority: [
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'ALGORITHM',
          'OPERATION_COMMON'
        ],
        crumbs: ['价格管理', '模版价格'],
        component: SuspenseComponent(templatePrice)
      }
    ]
  },
  {
    path: '/dataSet',
    component: NavLayout,
    routes: [
      {
        path: '/dataSet/list',
        exact: true,
        authority: ['OPERATION', 'OPERATION_COMMON'],
        crumbs: ['数据管理', '数据集'],
        component: SuspenseComponent(DataSet)
      }
    ]
  },
  {
    path: '/admin',
    component: NavLayout,
    routes: [
      {
        path: '/admin/admin-member-manage',
        exact: true,
        authority: ['OPERATION', 'OPERATION_COMMON'],
        crumbs: ['人员管理', '成员管理'],
        component: SuspenseComponent(AdminMenberManage)
      },
      {
        path: '/admin/admin-team-management',
        exact: true,
        authority: ['OPERATION', 'OPERATION_COMMON'],
        crumbs: ['人员管理', '团队管理'],
        component: SuspenseComponent(Management)
      }
    ]
  },
  {
    path: '/latex',
    component: NavLayout,
    routes: [
      {
        path: '/latex/converter',
        exact: true,
        authority: [
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'COMMON',
          'ALGORITHM',
          'OPERATION_COMMON'
        ],
        crumbs: ['Latex格式化工具', 'Latex格式化工具'],
        component: SuspenseComponent(LatexConverter)
      }
    ]
  },
  {
    path: '/manage-template',
    component: NavLayout,
    routes: [
      {
        path: '/manage-template/template-library',
        exact: true,
        authority: ['OPERATION', 'OPERATION_COMMON', 'OPERATION_COMMON_NORMAL'],
        crumbs: ['模版管理', '模版库'],
        component: SuspenseComponent(TemplateLibrary)
      },
      {
        path: '/manage-template/template-library/:status',
        exact: true,
        authority: ['OPERATION', 'OPERATION_COMMON'],
        crumbs: ['模版管理', '模版库'],
        component: SuspenseComponent(TemplateLibrary)
      }
    ]
  },
  {
    path: '/manpower',
    component: NavLayout,
    routes: [
      {
        path: '/manpower/data-analysis',
        exact: true,
        authority: ['OPERATION', 'GROUP_ADMIN', 'OPERATION_COMMON'],
        crumbs: ['人员管理', '人力数据分析'],
        component: SuspenseComponent(ManpowerAnalysis)
      }
    ]
  },
  {
    path: '/personnel',
    component: NavLayout,
    routes: [
      {
        path: '/personnel/member-manage',
        exact: true,
        authority: ['GROUP_OWNER', 'GROUP_ADMIN'],
        crumbs: ['人员管理', '成员管理'],
        component: SuspenseComponent(MemberManage)
      },
      {
        path: '/personnel/tags-manage',
        exact: true,
        authority: ['GROUP_OWNER', 'GROUP_ADMIN'],
        crumbs: ['人员管理', '标签管理'],
        component: SuspenseComponent(TagsManage)
      },
      {
        path: '/personnel/team-manage',
        exact: true,
        authority: ['GROUP_OWNER', 'GROUP_ADMIN'],
        crumbs: ['人员管理', '小组管理'],
        component: SuspenseComponent(TeamManage)
      },
      {
        path: '/personnel/info',
        exact: true,
        authority: [
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'COMMON_MARK',
          'COMMON_INSPECT'
        ],
        crumbs: ['个人中心', '个人信息'],
        component: SuspenseComponent(PersonnelInfo)
      }
    ]
  },
  //  题库查询
  {
    path: '/question',
    component: NavLayout,
    routes: [
      {
        path: '/question/list',
        exact: true,
        authority: [
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'ALGORITHM',
          'OPERATION_COMMON'
        ],
        crumbs: ['题库管理', '题库'],
        component: SuspenseComponent(QuestionList)
      },
      {
        path: '/question/questionBank',
        exact: true,
        authority: ['COMMON_MARK', 'COMMON_INSPECT'],
        crumbs: ['题库管理', '题库查询'],
        component: SuspenseComponent(QuestionBank)
      }
    ]
  },
  {
    path: '/questionnaire',
    component: BlankLayout,
    routes: [
      {
        path: '/questionnaire/satisfaction/:satisfactionType',
        exact: true,
        component: SuspenseComponent(SurveySatisfaction)
      },
      {
        path: '/questionnaire/result',
        exact: true,
        component: SuspenseComponent(SurveyResult)
      }
    ]
  },
  {
    path: '/demo',
    component: BlankLayout,
    routes: [
      {
        path: '/demo/mark-speech-slice',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(MarkSpeechSliceDemo)
      }
    ]
  },
  // 通用模版
  {
    path: '/low_code',
    component: BlankLayout,
    routes: [
      {
        path: '/low_code/template/:mode?/:id?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(LowCode)
      },
      {
        path: '/low_code/preview/:mode?/:id?',
        exact: true,
        authority: [
          'COMMON_MARK',
          'COMMON_INSPECT',
          'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON',
          'OPERATION_COMMON_NORMAL'
        ],
        component: SuspenseComponent(LowCodePreview)
      }
    ]
  },
  {
    path: '/cleaned-data',
    component: NavLayout,
    routes: [
      {
        path: '/cleaned-data/result',
        exact: true,
        authority: [
          // 'COMMON',
          // 'ALGORITHM',
          'OPERATION',
          'GROUP_OWNER',
          'GROUP_ADMIN',
          'OPERATION_COMMON'
        ],
        component: SuspenseComponent(CleanedData)
      }
    ]
  },
  {
    path: '/dataRetrieval',
    component: NavLayout,
    routes: [
      {
        path: '/dataRetrieval/dataRetrieval',
        // exact: true,
        authority: ['OPERATION', 'OPERATION_COMMON', 'OPERATION_COMMON_NORMAL'],
        crumbs: ['数据管理', '数据检索'],
        component: SuspenseComponent(DataRetrieval)
      }
    ]
  },
  {
    path: '/',
    exact: true,
    strict: true,
    redirect: '/login',
    component: SuspenseComponent(LoginComponent)
  },
  {
    path: '*',
    redirect: '/result/no-match',
    component: SuspenseComponent(NoMatch)
  }
];
export default routes;
