export const MARK_TYPE = {
  REWRITE: {
    APPEND: 'ConversationRewriteAppend',
    APPEND_QUESTION: 'QuestionReviewAppend',
    APPEND_AUTO_ADD: 'ArtificialQuestion',
    QUESTION_REVIEW: 'QuestionReview'
  }
};

export const TEMPLATE_MARK_TYPE = {
  SFT: 'ConversationRewrite',
  RM: 'ConversationSort'
};

/**
 * 项目流程字段
 * NEITHER 创建
 * APPEND 追加
 * COPY 复制
 * */
export const CONTROL_PROJECT_FLOW_TEXT = {
  NEITHER: 'neither',
  APPEND: 'append',
  COPY: 'copy'
};

/**
 * 选择数据类型
 */
export const SELECT_URL_KIND_NUM = {
  INPUT: 0,
  SELECT: 1
};

export default {};
