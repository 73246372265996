/* eslint-disable prefer-template */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-lonely-if */
/* eslint-disable no-plusplus */
/* eslint-disable quotes */
/* eslint-disable function-paren-newline */
import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios, { AxiosPromise } from 'axios';
import { message, Modal } from 'antd';
import { v1 as uuidv1, v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js';
import COS from 'cos-js-sdk-v5';
import qs from 'querystring';
import {
  Log,
  LogGroup,
  AsyncClient,
  PutLogsRequest
} from 'tencentcloud-cls-sdk-js-web';
import javascript from 'highlight.js/lib/languages/javascript';
import { addShortcutKyes, queryShortcutKyes } from '@/annotation/api/mark';
import shortcut, { newestTime } from '@/annotation/constants/Shortcut';
import { SUFFIX_NAME_TO_NUM } from '@/constants/FileType';
import { SELECT_URL_KIND_NUM } from '@/project/constants/type';
import { SELECT_TYPE_LIST } from '@/annotation/constants/MarkSFT';
import stores from '@/store';
import { TYPE_OBJ } from '@/low_code/utils/tools';
import { platform, baseURL } from './config';
import { MarkAttrListItem } from '../annotation/data/interface';
import 'highlight.js/styles/vs2015.css';

const { confirm } = Modal;

hljs.registerLanguage('javascript', javascript);

interface AttrType {
  id: string;
  type: string;
  label?: string;
  name?: string;
  value?: string;
  defaultValue?: string;
  children?: Array<any>;
  rules?: Array<any>;
}

interface FragmentType {
  id: number;
  index?: number;
  start: number;
  end: number;
  status?: number;
  attrRules?: string[];
}

interface MarkResultType {
  isCorrect: number;
  rejectReason: string;
}

interface FragmentListType {
  audioCut: FragmentType;
  markAttrList: MarkAttrListItem[];
  markResult?: MarkResultType;
}

interface iframeMsgType {
  type: string;
  progress?: number;
  value?: boolean;
}

const reverseHtmlEntities: any = {
  '&amp;': '&amp;',
  '&nbsp;': ' ',
  '&': '&amp;',
  '<': '&lt;',
  '≤': '&le;',
  '≥': '&ge;',
  '>': '&gt;'
  // [`"`]: '&quot;',
  // [`'`]: '&#039;'
};
const reverseRegexEnities = new RegExp(
  Object.keys(reverseHtmlEntities).join('|'),
  'g'
);

export const reverseLatexEscapeSymbol = (originText: string): string => {
  if (!originText) return '';
  if (originText) return originText;
  const escapeSymbol: any = originText.replace(
    reverseRegexEnities,
    (match) => reverseHtmlEntities[match]
  );
  return escapeSymbol;
};

const htmlEntities: any = {
  // '&amp;': '&amp;',
  '&amp;lt;': '<',
  '&amp;gt;': '>',
  '&amp;': '\\&',
  '&lt;': '<',
  '&le;': '≤',
  '&ge;': '≥',
  '&gt;': '>',
  '&': '\\&'
  // '&quot;': `"`,
  // '&#039;': `'`
};
const regexEnities = new RegExp(Object.keys(htmlEntities).join('|'), 'g');
const BlobType = [
  {
    name: '.xls',
    fileType: 'Microsoft Excel',
    type: 'application/vnd.ms-excel'
  },
  {
    name: '.xlsx',
    fileType: 'Microsoft Excel (OpenXML)',
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  },
  { name: '.csv', fileType: 'CSV', type: 'text/csv' },
  { name: '.doc', fileType: 'Microsoft Word', type: 'application/msword' },
  {
    name: '.docx',
    fileType: 'Microsoft Word (OpenXML)',
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  },
  { name: '.pdf', fileType: 'PDF', type: 'application/pdf' },
  {
    name: '.ppt',
    fileType: 'Microsoft PowerPoint',
    type: 'application/vnd.ms-powerpoint'
  },
  {
    name: '.pptx',
    fileType: 'Microsoft PowerPoint (OpenXML)',
    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  },
  { name: '.png', fileType: '便携式网络图形（PNG）', type: 'image/png' },
  { name: '.gif', fileType: 'GIF', type: 'image/gif' },
  { name: '.jpeg', fileType: 'JPEG 图片', type: 'image/jpeg' },
  { name: '.jpg', fileType: 'JPEG 图片', type: 'image/jpeg' },
  { name: '.mp3', fileType: 'MP3 音频', type: 'audio/mpeg' },
  { name: '.aac', fileType: 'AAC 音频', type: 'audio/aac' },
  { name: '.html', fileType: '超文本标记语言 (HTML)', type: 'text/html' },
  { name: '.css', fileType: 'CSS', type: 'text/css' },
  { name: '.js', fileType: 'JavaScript', type: 'text/javascript' },
  { name: '.json', fileType: 'JSON 格式', type: 'application/json' },
  { name: '.abw', fileType: 'AbiWord 文档', type: 'application/x-abiword' },
  {
    name: '.arc',
    fileType: '存档文档(多个文件嵌入)',
    type: 'application/x-freearc'
  },
  { name: '.avi', fileType: 'AVI: 音频视频交错', type: 'video/x-msvideo' },
  {
    name: '.azw',
    fileType: '亚马逊Kindle电子书格式',
    type: 'application/vnd.amazon.ebook'
  },
  {
    name: '.bin',
    fileType: '任何类型的二进制数据',
    type: 'application/octet-stream'
  },
  { name: '.bmp', fileType: 'Windows OS/2位图图形', type: 'image/bmp' },
  { name: '.bz', fileType: 'BZip 存档', type: 'application/x-bzip' },
  { name: '.bz2', fileType: 'BZip2 存档', type: 'application/x-bzip2' },
  { name: '.csh', fileType: 'C-Shell 脚本', type: 'application/x-csh' },
  {
    name: '.eot',
    fileType: 'MS嵌入式OpenType字体',
    type: 'application/vnd.ms-fontobject'
  },
  { name: '.epub', fileType: '电子出版物(EPUB)', type: 'application/epub+zip' },
  { name: '.htm', fileType: '超文本标记语言 (HTML)', type: 'text/html' },
  { name: '.ico', fileType: 'Icon 格式', type: 'image/vnd.microsoft.icon' },
  { name: '.ics', fileType: 'iCalendar 格式', type: 'text/calendar' },
  {
    name: '.jar',
    fileType: 'Java Archive (JAR)',
    type: 'application/java-archive'
  },
  { name: '.jsonld', fileType: 'JSON-LD 格式', type: 'application/ld+json' },
  {
    name: '.mid',
    fileType: '乐器数字接口(MIDI)',
    type: 'audio/midi'
  },
  {
    name: '.midi',
    fileType: '乐器数字接口(MIDI)',
    type: 'audio/midi'
  },
  { name: '.mjs', fileType: 'JavaScript 模块', type: 'text/javascript' },
  { name: '.mpeg', fileType: 'MPEG 视频', type: 'video/mpeg' },
  {
    name: '.mpkg',
    fileType: '苹果安装程序包',
    type: 'application/vnd.apple.installer+xml'
  },
  {
    name: '.odp',
    fileType: 'OpenDocument演示文档',
    type: 'application/vnd.oasis.opendocument.presentation'
  },
  {
    name: '.ods',
    fileType: 'OpenDocument 电子表格文件',
    type: 'application/vnd.oasis.opendocument.spreadsheet'
  },
  {
    name: '.odt',
    fileType: 'OpenDocument 文本文档',
    type: 'application/vnd.oasis.opendocument.text'
  },
  { name: '.oga', fileType: 'OGG 音频', type: 'audio/ogg' },
  { name: '.ogv', fileType: 'OGG 视频', type: 'video/ogg' },
  { name: '.ogx', fileType: 'OGG', type: 'application/ogg' },
  { name: '.otf', fileType: 'OpenType 字体', type: 'font/otf' },
  { name: '.rar', fileType: 'RAR 存档', type: 'application/x-rar-compressed' },
  { name: '.rtf', fileType: '富文本格式 (RTF)', type: 'application/rtf' },
  { name: '.sh', fileType: 'Bourne shell 脚本', type: 'application/x-sh' },
  { name: '.svg', fileType: '可缩放矢量图形 (SVG)', type: 'image/svg+xml' },
  {
    name: '.swf',
    fileType: '小型web格式 (SWF) or Adobe Flash document',
    type: 'application/x-shockwave-flash'
  },
  { name: '.tar', fileType: 'Tape 归档(TAR)', type: 'application/x-tar' },
  { name: '.tif', fileType: '标记图像文件格式 (TIFF)', type: 'image/tiff' },
  {
    name: '.tiff',
    fileType: 'Tagged Image File Format (TIFF)',
    type: 'image/tiff'
  },
  { name: '.ttf', fileType: 'rueType 字体', type: 'font/ttf' },
  { name: '.txt', fileType: 'Text', type: 'text/plain' },
  { name: '.vsd', fileType: 'Microsoft Visio', type: 'application/vnd.visio' },
  { name: '.wav', fileType: '波形音频格式', type: 'audio/wav' },
  { name: '.weba', fileType: 'WEBM 音频', type: 'audio/webm' },
  { name: '.webm', fileType: 'EBM 视频', type: 'video/webm' },
  { name: '.webp', fileType: 'WEBP 图片', type: 'image/webp' },
  { name: '.woff', fileType: '网页开放字体格式 (WOFF)', type: 'font/woff' },
  { name: '.woff2', fileType: '网页开放字体格式 (WOFF)', type: 'font/woff2' },
  { name: '.xhtml', fileType: 'XHTML', type: 'application/xhtml+xml' },
  {
    name: '.xml',
    fileType: 'XML',
    type: 'application/xml'
  },
  { name: '.xul', fileType: 'XUL', type: 'application/vnd.mozilla.xul+xml' },
  { name: '.zip', fileType: 'ZIP', type: 'application/zip' },
  {
    name: '.3gp',
    fileType: '3GPP audio/video 容器',
    type: 'video/3gpp'
  },
  {
    name: '.3g2',
    fileType: '3GPP2 audio/video 容器',
    type: 'video/3gpp2'
  },
  { name: '.7z', fileType: '7-zip', type: 'application/x-7z-compressed' }
];

const md: any = new MarkdownIt({
  html: true,
  xhtmlOut: true,
  highlight(str: string, lang: string) {
    if (lang && hljs.getLanguage(lang)) {
      const highlighted = hljs.highlightAuto(str).value;
      return `<pre class="hljs"><code>${highlighted}</code></pre>`;
    }

    return `<pre class="hljs"><code>${md.utils.escapeHtml(str)}</code></pre>`;
  }
});

// 自定义渲染内联代码块的处理函数
const originalRenderInline = md.renderer.rules.code_inline;
md.renderer.rules.code_inline = (
  tokens: string,
  idx: number,
  options: any,
  env: any,
  self: any
): any => {
  const token: any = tokens[idx];
  const content = token.content;

  // 尝试将内联代码内容进行高亮
  try {
    const highlighted = hljs.highlightAuto(content).value;
    return `<code class="hljs">${highlighted}</code>`;
  } catch (error) {
    // 高亮失败，仍然渲染为普通的内联代码
    return originalRenderInline(tokens, idx, options, env, self);
  }
};

const showAttr = (className: string): void => {
  const eles = document.querySelectorAll(className);
  Array.prototype.slice.call(eles).forEach((item) => {
    item.style.display = item.style.display === 'none' ? 'block' : 'none';
  });
};

const resetTransform = (className: string): void => {
  const node = document.getElementsByClassName(className);
  Array.prototype.slice.call(node).forEach((item) => {
    item.style.transform = 'unset';
  });
};

const compareConfigItems = (obj1: AttrType, obj2: AttrType): number => {
  const configList = ['radio', 'checkbox', 'textarea', 'latex'];
  return configList.indexOf(obj1.type) - configList.indexOf(obj2.type);
};

const downloadJSONFile = (url: string, name: string): void => {
  axios.get(url).then((res: any) => {
    if (res.data === '') {
      messageInfo({
        message: '文件下载失败，请联系平台处理'
      });
      return;
    }
    const blob = new Blob([res.data], {
      type: 'application/json;charset=UTF-8'
    });
    const aElement = document.createElement('a');
    const href = window.URL.createObjectURL(blob);
    aElement.href = href;
    aElement.download = `${name}.json`;
    document.body.appendChild(aElement);
    aElement.click();
    document.body.removeChild(aElement);
    window.URL.revokeObjectURL(href);
  });
};

const changeType = (name: string): string => {
  for (let i = 0; i < BlobType.length; i += 1) {
    if (name === BlobType[i].name) {
      return BlobType[i].type;
    }
  }
  return '';
};
const downloadDataSetFile = (url: string, name: string): any => {
  return axios({ url, method: 'get', responseType: 'blob' }).then(
    (res: any) => {
      if (res.data === '') {
        messageInfo({
          message: '文件下载失败，请联系平台处理'
        });
        return;
      }
      const blob = new Blob([res.data], {
        type: `${changeType(`.${name.split('.').slice(-1)[0]}`)};charset-UTF-8;`
      });
      const aElement = document.createElement('a');
      const href = window.URL.createObjectURL(blob);
      aElement.href = href;
      aElement.download = name;
      document.body.appendChild(aElement);
      aElement.click();
      document.body.removeChild(aElement);
      window.URL.revokeObjectURL(href);
    }
  );
};
const downloadFileByUrl = (url: string): void => {
  const aElement = document.createElement('a');
  aElement.href = url;
  document.body.appendChild(aElement);
  aElement.click();
  document.body.removeChild(aElement);
};

const downloadPdfByUrl = (url: string, name: string): void => {
  axios
    .get(url, {
      responseType: 'arraybuffer'
    })
    .then((res: any) => {
      if (res.data === '') {
        messageInfo({
          message: '文件下载失败，请联系平台处理'
        });
        return;
      }
      const blob = new Blob([res.data], {
        type: 'application/pdf;charset-UTF-8;'
      });
      const aElement = document.createElement('a');
      const href = window.URL.createObjectURL(blob);
      aElement.href = href;
      aElement.download = `${name}.pdf`;
      document.body.appendChild(aElement);
      aElement.click();
      document.body.removeChild(aElement);
      window.URL.revokeObjectURL(href);
    });
  // window.location.href = url;
};

const showStatus = (statusList: any, status: number): string => {
  let st = '';
  if (statusList && statusList.length > 0) {
    statusList.forEach((item: any) => {
      if (status === parseInt(item.value, 10)) {
        st = item.name;
      }
    });
  }
  return st;
};

const setQualifiedRate = (
  qualifiedNum: number,
  totalNum: number,
  percent: boolean
): any => {
  if (totalNum === 0) {
    // return '-';
    return 0;
  }
  let value: number;
  if (Number.isInteger((qualifiedNum / totalNum) * 100)) {
    value = (qualifiedNum / totalNum) * 100;
  } else {
    value = parseFloat(((qualifiedNum / totalNum) * 100).toFixed(2));
  }
  if (percent) {
    return `${value}%`;
  }
  return value;
};

const messageInfo = (params: any): void => {
  message.success({
    content: params.message,
    icon: ' ',
    duration: params.duration || 1
  });
};

const getPlatformName = (): string => {
  if (platform === 'MANAGER') {
    return '管理';
  }
  if (platform === 'MARK') {
    return '标注';
  }
  if (platform === 'CUSTOMER') {
    return '客户';
  }
  return '';
};

const getHost = (): string => window.location.host;

const getProtocol = (): string => window.location.protocol;

const getWSUrl = (url: string): string => {
  const host = getHost();
  const protocol = getProtocol();
  const wsUrl = `${
    protocol === 'https:' ? 'wss:' : 'ws:'
  }//${host}${baseURL}${url}`;
  return wsUrl;
};

const markTypeSwitch = (
  val: string | undefined,
  type: string,
  subMarkType = ''
): string => {
  let suffix = '';

  if (val === 'AudioCut') {
    suffix = 'audio-cut';
  } else if (val === 'AudioCharLevelCut') {
    suffix = 'audio-char-level-cut';
  } else if (val === 'AudioTrans') {
    suffix = 'audio-trans';
  } else if (val === 'VideoAudioCut') {
    suffix = 'video-audio-cut';
  } else if (val === 'ImageDrawBox') {
    if (type === 'modify') {
      return 'modify';
    }
    if (type === 'mark') {
      return 'mark';
    }
    if (type === 'review') {
      return 'review';
    }
  } else if (val === 'ImageGlobal') {
    suffix = 'image-global';
  } else if (val === 'ABX') {
    suffix = 'abx';
  } else if (val === 'VideoMark') {
    suffix = 'video';
  } else if (val === 'ConversationRewrite') {
    if (subMarkType === 'ConversationRewriteMultiRoundOnline') {
      return 'multiRound-online';
    }
    return 'conversation-rewrite';
  } else if (val === 'ConversationSort') {
    return 'conversation-sort';
  } else if (val === 'QuestionEntering') {
    return 'question-input';
  } else if (val === 'MultiQuestion') {
    return 'test-enter';
  } else if (val === 'ArtificialQuestion') {
    return 'auto-add-test';
  } else if (val === 'ConversationCompare') {
    // 此处为新数据格式
    if (subMarkType === 'ConversationCompareOnline') {
      return 'conversation-compare-online';
    }
    return 'conversation-compare';
  } else if (val === 'ConversationCompareOnline') {
    // 兼容历史数据
    return 'conversation-compare-online';
  }
  return suffix ? `${type}-${suffix}` : type;
};

const verifyAddress = (url: string): AxiosPromise =>
  axios({
    method: 'get',
    url,
    headers: { Range: 'bytes=0-1024' }
  });

const judgeAttrIsInRulesAndIsTrue = (
  rules: string[],
  attr: string
): boolean => {
  if (!rules) {
    return false;
  }
  if (rules.length === 0) {
    return false;
  }
  // v2.7.1之前：rules=['required', 'noNumber']
  if (rules.includes(attr)) {
    return true;
  }
  // v2.7.1：rules格式修改为["{\"required\":true,\"disabled\":true}", "{\"noNumber\": true}"]
  const newRules: string[] = [];
  rules.forEach((item: string) => {
    if (item.includes(':')) {
      newRules.push(JSON.parse(item));
    }
  });
  const temp: any = _.find(newRules, { [attr]: true });
  if (temp) {
    return true;
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const dealNum = (fragmentData: any, initialValid: number) => {
  const tempStatisticsData = fragmentData ? fragmentData.statisticalData : '';
  const currentValid = fragmentData && fragmentData.valid ? 0 : 1;
  if (initialValid !== currentValid) {
    tempStatisticsData.forEach((item: any) => {
      if (item.fromNum) {
        if (initialValid) {
          item.num = 0;
        } else {
          item.fromNum = 0;
        }
      }
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return tempStatisticsData;
};

const findMarkInfoById = (id: string, markList: any): number => {
  let findIndex = -1;
  if (!id || id.length <= 0) {
    return findIndex;
  }
  markList.forEach((item: any, index: number) => {
    if (item && item.markGraph && item.markGraph.id === id) {
      findIndex = index;
    }
  });
  return findIndex;
};

const deleteHelperFieldOfMarkAttr = (
  attr: MarkAttrListItem[]
): MarkAttrListItem[] => {
  const newAttr: MarkAttrListItem[] = _.cloneDeep(attr);
  if (newAttr.length > 0) {
    newAttr.forEach((attrItem: any) => {
      delete attrItem.children;
      delete attrItem.defaultValue;
      delete attrItem.rules;
      // 兼容老数据：2.6.1修改TemplateInfo数据结构之前的数据
      delete attrItem.isRequired;
    });
  }
  return newAttr;
};

const formatSeconds = (seconds: number): Array<string> => {
  seconds = Math.round(seconds);
  let hours = 0;
  let minutes = 0;
  if (seconds > 60) {
    minutes = Math.floor(seconds / 60);
    seconds %= 60;
    if (minutes > 60) {
      hours = Math.floor(minutes / 60);
      minutes %= 60;
    }
  }
  return [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0')
  ];
};

const setTaskNameToSession = (taskName: string): void => {
  sessionStorage.setItem('taskName', taskName);
};

const getTaskNameFromSession = (): string => {
  const name: string =
    sessionStorage.getItem('taskName') ||
    localStorage.getItem('taskName') ||
    '';
  return name;
};

const getTaskNameFromLocal = (): string => {
  const name: string = localStorage.getItem('taskName') || '';
  return name;
};

const sumUnCuttedDuration = (
  markData: FragmentListType[],
  duration: number
): any => {
  const markDataCp = markData.slice();
  const markDataSorted = markDataCp.sort(compareStart);
  let sum = 0;
  let fragment: any = '';
  if (markDataSorted.length === 0) {
    return [duration * 1000, null];
  }
  if (markDataSorted.length > 0 && markDataSorted[0].audioCut.start > 0) {
    sum += markDataSorted[0].audioCut.start - 0;
    fragment = markDataSorted[0].audioCut;
  }
  markDataSorted.forEach((item, index, arr) => {
    if (arr[index + 1] && arr[index + 1].audioCut.start > item.audioCut.end) {
      sum += arr[index + 1].audioCut.start - item.audioCut.end;
      if (!fragment) {
        fragment = item.audioCut;
      }
    }
  });
  if (
    markDataSorted[markDataSorted.length - 1]?.audioCut.end <
    duration * 1000
  ) {
    sum +=
      duration * 1000 - markDataSorted[markDataSorted.length - 1].audioCut.end;
    if (!fragment) {
      fragment = markDataSorted[markDataSorted.length - 1].audioCut;
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return [sum, fragment];
};

const compareStart = (
  item1: FragmentListType,
  item2: FragmentListType
): number => (item1.audioCut.start > item2.audioCut.start ? 1 : -1);

const handleIframePlay = (instruction: string, params?: any): void => {
  const iframeNode = document.getElementById('video');
  if (!iframeNode) {
    return;
  }
  const content = (iframeNode as HTMLIFrameElement).contentWindow;
  const msg: iframeMsgType = {
    type: instruction
  };
  if (instruction === 'jump-play' && params) {
    msg.progress = params * 100;
  }
  if (instruction === 'video-muted' && params) {
    msg.value = params;
  }
  content?.postMessage(msg, '*');
};

const getMachineAndBrowserInfo = (): { userAgentData: any; userAgent: any } => {
  const nav: any = window.navigator; // sometimes, typescript is so annoying
  return {
    userAgentData: nav.userAgentData,
    userAgent: nav.userAgent
  };
};

// 找到所有公式
export const findAllKatex = (str: string): any => {
  const regex = /\$(.*?)\$/g;
  const matches = [];
  let lastIndex = 0;
  let match;
  let katexNum = 0;

  while ((match = regex.exec(str)) !== null) {
    if (lastIndex < match.index) {
      matches.push({
        content: str.slice(lastIndex, match.index),
        index: lastIndex
      });
    }
    matches.push({
      content: match[1],
      index: match.index + 1,
      type: 'katex'
    });
    katexNum += 1;
    lastIndex = regex.lastIndex;
  }

  if (lastIndex < str.length) {
    matches.push({
      content: str.slice(lastIndex),
      index: lastIndex
    });
  }

  return { katexNum, matches, isKatex: katexNum > 0 };
};

export const regexes = {
  latex: /\$\$([^]*?)\$\$/g,
  markdown: /\$#([^]*?)#\$/g,
  text: /[^$#]+/g
};

export const getMatchContents = (input: string): any[] => {
  if (!input) return [];
  // return input.match(/\$#[^]*?#\$|\$\$[^]*?\$\$|\S+/g) || [];
  return (
    input.match(/\$#.*?#\$|\$\$.*?\$\$|\$.*?\$|[^$]+?(?=(\$#|\$\$|\$)|$)/g) ||
    []
  );
};
const findTableContents = (input: string): string[] => {
  let pos = 0;
  let start = 0;
  let results: string[] = [];
  const len = input.length;

  start = input.indexOf('table{', pos);

  if (start !== -1) {
    let l = 0;
    let r = 0;
    for (let i = start + 5; i < len; i += 1) {
      const str = input[i];
      if (str === '{') {
        l += 1;
      }
      if (str === '}') {
        r += 1;
      }
      if (l === r) {
        results.push(input.slice(start + 6, i));
        pos = i;
        break;
      }
    }
  } else {
    results = [input];
  }
  return results;
};
export const findCenterLineContent = (
  input: string
): { isCenter: boolean; content: string } => {
  const len = input.length;
  let l = 0;
  let r = 0;
  const start = input.indexOf('centerline{');
  let content = input;
  const isCenter = start !== -1;
  if (isCenter) {
    for (let i = start + 10; i < len; i += 1) {
      const str = input[i];
      const before = input[i - 1];
      if (str === '{') {
        l += 1;
      }
      if (str === '}' && before !== '\\') {
        r += 1;
      }
      if (l === r) {
        content = input.slice(start + 11, i);
        break;
      }
    }
  }

  return { isCenter, content };
};
export const renderContents = (
  input: string,
  type = 0,
  ignoreReverse = false
): string => {
  if (!input) return '';
  const imgListObj: any = {};
  // 如果是number类型 转成字符串
  input = typeof input === 'number' ? String(input) : input;
  if (!ignoreReverse) {
    input = input.replace(/<img[^>]*>/g, (match, pos) => {
      imgListObj[`%IMG_TAG${pos}%`] = match;
      return `%IMG_TAG${pos}%`;
    });

    // 替换换行符
    input = input.replace(/<br>/g, '\n');

    // 将单个字符做替换处理
    input = input.replace(
      reverseRegexEnities,
      (match) => reverseHtmlEntities[match]
    );

    // 替换原始内容中的img标签
    const imgUrlList = Object.keys(imgListObj);
    imgUrlList.forEach((item) => {
      input = input.replace(item, imgListObj[item]);
    });
  }
  const strs = getMatchContents(input);
  let renderStr = '';
  strs.forEach((texts) => {
    regexes.markdown.lastIndex = 0;
    regexes.latex.lastIndex = 0;
    const matchMarkdown = regexes.markdown.exec(texts);
    const matchLatex = regexes.latex.exec(texts);
    if (matchMarkdown) {
      const tableContents = findTableContents(matchMarkdown[1]);
      let replaceTable = matchMarkdown[1];
      if (tableContents.length > 0) {
        tableContents.forEach((tableStr) => {
          const regex = /^table{/;
          const isTableStart = regex.test(replaceTable);
          const value = isTableStart ? `table{${tableStr}}` : `${tableStr}`;
          replaceTable = replaceTable.replace(value, () => {
            let rows = tableStr.split(/\|\n\|/g);
            rows = rows.map((item) =>
              item
                .replace(/\|\n/g, '|')
                .replace(/^\n|\n$/g, '')
                .replace(/\n/g, '%next%')
            );
            rows.forEach((item: string, index: number) => {
              if (!item?.startsWith('|')) {
                rows[index] = `|${rows[index]}`;
              }
              if (!item?.endsWith('|')) {
                rows[index] += '|';
              }
            });

            // 如果table数据自带分隔符则不进行 添加分隔符操作
            if (
              rows[1]?.startsWith('| - ') ||
              rows[1]?.startsWith('| - |') ||
              rows[1]?.startsWith('| -|') ||
              rows[1]?.startsWith('|-|')
            ) {
              return rows.join('\n');
            }

            // 没有分隔符的话 主动添加分隔符
            const headSimple = rows[0].split('|');
            headSimple.pop();
            headSimple.shift();
            let headLine = '';
            headSimple.forEach(() => {
              headLine += '|-';
            });
            headLine += '|';
            rows.splice(1, 0, headLine);
            return rows.join('\n');
          });
        });
      }

      let markdownText = replaceTable;

      markdownText = markdownText.replace(
        regexes.latex,
        (a: string, b: string) => {
          let data = '';
          const { isCenter, content } = findCenterLineContent(b);
          let latexText = isCenter ? content : b;
          latexText = latexText.replace(
            regexEnities,
            (match) => htmlEntities[match]
          );

          try {
            let spaceLatexText: any = latexText.split(' ');
            spaceLatexText = spaceLatexText.join('\\text{ }\\text{ }');
            // @ts-ignore
            data = window.MathJax.tex2mml(
              (window as any).supportSpace ? spaceLatexText : latexText,
              {
                display: false
              }
            );
          } catch (error: any) {
            // @ts-ignore
            if (window.$Aegis) {
              // @ts-ignore
              window.$Aegis.report({
                msg: 'latex_error',
                ext1: latexText,
                ext2: error.message
              });
            }
            console.log('mathJax error', error);
          }

          // 清除markdown中latex标签的换行符 兼容markdown-it因为换行符导致的不渲染问题
          data = data?.replace(/\n/g, '');
          return `<span class="latex-block" data-origin-latex="${encodeURIComponent(
            latexText
          )}">${data}</span>`;
        }
      );

      markdownText = md.render(markdownText);

      markdownText = markdownText
        .replace(/<table>/g, '<div class="table-container"><table>')
        .replace(/<\/table>/g, '</table></div>');
      renderStr += markdownText;
    } else if (matchLatex) {
      const { isCenter, content } = findCenterLineContent(matchLatex[1]);
      let latexString = '';
      let latexText = content;
      latexText = latexText.replace(
        regexEnities,
        (match) => htmlEntities[match]
      );

      try {
        let spaceLatexText: any = latexText.split(' ');
        spaceLatexText = spaceLatexText.join('\\text{ }\\text{ }');
        // @ts-ignore
        latexString = window.MathJax.tex2mml(
          (window as any).supportSpace ? spaceLatexText : latexText,
          {
            display: false
          }
        );
        // }
        renderStr += isCenter
          ? `<div class="centerline"><span class="latex-block" data-origin-latex="${encodeURIComponent(
              latexText
            )}">${latexString}</span></div>`
          : `<span class="latex-block" data-origin-latex="${encodeURIComponent(
              latexText
            )}">${latexString}</span>`;
      } catch (error: any) {
        // @ts-ignore
        if (window.$Aegis) {
          // @ts-ignore
          window.$Aegis.report({
            msg: 'latex_error',
            ext1: latexText,
            ext2: error.message
          });
        }
        console.log('mathJax error', error);
      }
    } else {
      renderStr += texts;
    }
  });
  renderStr = renderStr.replace(/%next%/g, '\n');
  if (type === 1) {
    // 暂时取消竖式渲染黑块的问题
    // renderStr = renderStr.replace(/fill="/g, 'fill="#');
    // renderStr = renderStr.replace(/stroke="/g, 'stroke="#');
  }

  // 替换mark标签
  renderStr = renderStr.replace(
    /&lt;mark class=&quot;marker-red&quot;&gt;/g,
    '<mark class="marker-red">'
  );

  renderStr = renderStr.replace(/&lt;\/mark&gt;/g, '</mark>');
  // 替换图片标签
  renderStr = renderStr.replace(/&lt;img src=&quot;/g, '<img src="');
  renderStr = renderStr.replace(/&quot;&gt;/, '">');

  return renderStr;
};

export const renderTextContent = (
  input: string,
  type = 0,
  ignoreReverse = false,
  spanReverse = false // 默认会把数据放到一行中，这个参数控制是否加p标签
): string => {
  if (!input) return '';
  const imgListObj: any = {};
  // 如果是number类型 转成字符串
  input = typeof input === 'number' ? String(input) : input;
  // 对图片标签进行转换
  if (!ignoreReverse) {
    input = input.replace(/<img[^>]*>/g, (match, pos) => {
      imgListObj[`%IMG_TAG${pos}%`] = match;
      return `%IMG_TAG${pos}%`;
    });

    // 替换换行符
    input = input.replace(/<br>/g, '\n');

    input = input.replace(/<mark class="marker-red">/g, '%MARK_START%');
    input = input.replace(/<\/mark>/g, '%MARK_END%');

    // 将单个字符做替换处理
    input = input.replace(
      reverseRegexEnities,
      (match) => reverseHtmlEntities[match]
    );

    // 替换原始内容中的img标签
    const imgUrlList = Object.keys(imgListObj);
    imgUrlList.forEach((item) => {
      input = input.replace(item, imgListObj[item]);
    });
  }

  // table的正则
  let tableRegex = /\$#(?:table)?([\s\S]*?)#\$/g;
  let tableData: any = [];
  input = input.replace(tableRegex, (match, p1) => {
    // 将捕获的内容添加到数组中
    tableData.push(p1);

    // 返回替换文本，这里使用数组的长度来确定顺序
    return `%TABLE_${tableData.length}%`;
  });

  // 通过input拆分段落
  let pArray = input.split('\n');

  let fullText = '';
  pArray.forEach((inputItem) => {
    const text = spanReverse
      ? `${formatTextItem(inputItem, type, tableData)}`
      : `<p id=${uuidv4()} style="min-height: 14px">${formatTextItem(
          inputItem,
          type,
          tableData
        )}</p>`;
    fullText += text;
  });
  return fullText;
};

export const formatTextItem = (
  textItem: string,
  type: number,
  tableData = []
): string => {
  // 还原table数据
  if (tableData.length > 0) {
    tableData.forEach((item, index) => {
      const reg = `%TABLE_${index + 1}%`;
      // 此处用函数返回为专门写法 兼容数据中有$$时直接使用``进行字符替换会丢失$
      textItem = textItem.replace(reg, () => `$#${item}#$`);
    });
  }
  const strs = getMatchContents(textItem);
  let renderStr = '';
  strs.forEach((texts) => {
    regexes.markdown.lastIndex = 0;
    regexes.latex.lastIndex = 0;
    const matchMarkdown = regexes.markdown.exec(texts);
    const matchLatex = regexes.latex.exec(texts);
    if (matchMarkdown) {
      const tableContents = findTableContents(matchMarkdown[1]);
      let replaceTable = matchMarkdown[1];
      if (tableContents.length > 0) {
        tableContents.forEach((tableStr) => {
          const regex = /^table{/;
          const isTableStart = regex.test(replaceTable);
          const value = isTableStart ? `table{${tableStr}}` : `${tableStr}`;
          replaceTable = replaceTable.replace(value, () => {
            let rows = tableStr.split(/\|\n\|/g);
            rows = rows.map((item) =>
              item
                .replace(/\|\n/g, '|')
                .replace(/^\n|\n$/g, '')
                .replace(/\n/g, '%next%')
            );
            rows.forEach((item: string, index: number) => {
              if (!item?.startsWith('|')) {
                rows[index] = `|${rows[index]}`;
              }
              if (!item?.endsWith('|')) {
                rows[index] += '|';
              }
            });

            // 如果table数据自带分隔符则不进行 添加分隔符操作
            if (
              rows[1]?.startsWith('| - ') ||
              rows[1]?.startsWith('| - |') ||
              rows[1]?.startsWith('| -|') ||
              rows[1]?.startsWith('|-|')
            ) {
              return rows.join('\n');
            }

            // 没有分隔符的话 主动添加分隔符
            const headSimple = rows[0].split('|');
            headSimple.pop();
            headSimple.shift();
            let headLine = '';
            headSimple.forEach(() => {
              headLine += '|-';
            });
            headLine += '|';
            rows.splice(1, 0, headLine);
            return rows.join('\n');
          });
        });
      }

      let markdownText = replaceTable;

      markdownText = markdownText.replace(
        regexes.latex,
        (a: string, b: string) => {
          let data = '';
          const { isCenter, content } = findCenterLineContent(b);
          let latexText = isCenter ? content : b;
          latexText = latexText.replace(
            regexEnities,
            (match) => htmlEntities[match]
          );

          try {
            let spaceLatexText: any = latexText.split(' ');
            spaceLatexText = spaceLatexText.join('\\text{ }\\text{ }');
            // @ts-ignore
            data = window.MathJax.tex2mml(
              (window as any).supportSpace ? spaceLatexText : latexText,
              {
                display: false
              }
            );
          } catch (error: any) {
            // @ts-ignore
            if (window.$Aegis) {
              // @ts-ignore
              window.$Aegis.report({
                msg: 'latex_error',
                ext1: latexText,
                ext2: error.message
              });
            }
            console.log('mathJax error', error);
          }

          // 清除markdown中latex标签的换行符 兼容markdown-it因为换行符导致的不渲染问题
          data = data?.replace(/\n/g, '');
          return `<span class="latex-block" data-origin-latex="${encodeURIComponent(
            latexText
          )}">${data}</span>`;
        }
      );

      markdownText = md.render(markdownText);

      markdownText = markdownText
        .replace(/<table>/g, '<div class="table-container"><table>')
        .replace(/<\/table>/g, '</table></div>');
      renderStr += markdownText;
    } else if (matchLatex) {
      const { isCenter, content } = findCenterLineContent(matchLatex[1]);
      let latexString = '';
      let latexText = content;
      latexText = latexText.replace(
        regexEnities,
        (match) => htmlEntities[match]
      );

      try {
        let spaceLatexText: any = latexText.split(' ');
        spaceLatexText = spaceLatexText.join('\\text{ }\\text{ }');
        // @ts-ignore
        latexString = window.MathJax.tex2mml(
          (window as any).supportSpace ? spaceLatexText : latexText,
          {
            display: false
          }
        );
        renderStr += isCenter
          ? `<div class="centerline"><span class="latex-block" data-origin-latex="${encodeURIComponent(
              latexText
            )}">${latexString}</span></div>`
          : `<span class="latex-block" data-origin-latex="${encodeURIComponent(
              latexText
            )}">${latexString}</span>`;
      } catch (error: any) {
        // @ts-ignore
        if (window.$Aegis) {
          // @ts-ignore
          window.$Aegis.report({
            msg: 'latex_error',
            ext1: latexText,
            ext2: error.message
          });
        }
        console.log('mathJax error', error);
      }
    } else {
      renderStr += texts;
    }
  });
  renderStr = renderStr.replace(/%next%/g, '\n');
  if (type === 1) {
    renderStr = renderStr.replace(/fill="/g, 'fill="#');
    renderStr = renderStr.replace(/stroke="/g, 'stroke="#');
  }

  // 替换mark标签
  renderStr = renderStr.replace(
    /&lt;mark class=&quot;marker-red&quot;&gt;/g,
    '<mark class="marker-red">'
  );

  renderStr = renderStr.replace(/&lt;\/mark&gt;/g, '</mark>');
  // 替换图片标签
  renderStr = renderStr.replace(/&lt;img src=&quot;/g, '<img src="');
  renderStr = renderStr.replace(/&quot;&gt;/, '">');

  renderStr = renderStr.replace(/%MARK_START%/g, '<mark class="marker-red">');
  renderStr = renderStr.replace(/%MARK_END%/g, '</mark>');
  return renderStr;
};

// 排序子对象转数组对象
export const sortObjToArrayObj = (obj: any, actKey = ''): any => {
  const arr: any = [];
  // eslint-disable-next-line guard-for-in
  for (const i in obj) {
    if (typeof obj[i] === 'string') {
      arr.push({
        key: i,
        value: obj[i],
        rank: 1,
        isOriginAnswer: i === actKey
      });
    }
  }
  return arr;
};

export const sortObjToRankList = (obj: any, parentIdx: number): any => {
  const objKeys = Object.keys(obj);
  const arr = [];
  let num = 0;
  for (let i = 0; i < objKeys.length; i++) {
    if (typeof obj[objKeys[i]] === 'string') {
      const tagTextNum = num + 1;
      arr.push({
        row: parentIdx,
        value: tagTextNum,
        label: tagTextNum
      });
      num += 1;
    }
  }

  return {
    list: arr
  };
};

// 排序任务检查是否有空数据
export const sortValideKeyIsNull = (list: any, key: string): boolean => {
  // 取出所有response数据
  const scoreArray = list.reduce(
    (pre: any, cur: any) => [...pre, ...cur.response],
    []
  );
  const isNullData = scoreArray.find((item: any) => item[key] === undefined);

  if (isNullData) {
    return true;
  }
  return false;
};

// 计算原始内容
const countSortTaskNum = (valueList: any): any => {
  let promptNum = 0;
  let originAnswerNum = 0;
  let editAnswerNum = 0;
  valueList.conversation.forEach((conversationItem: any) => {
    const { prompt, response } = conversationItem;
    // 问题求和
    promptNum += prompt.length;
    response.forEach((responseItem: any) => {
      const { value, isNew = false } = responseItem;
      if (isNew) {
        if (typeof value === 'string') {
          editAnswerNum += value.length;
        } else {
          // 特殊处理错误数据
          editAnswerNum += value.value ? value.value.length : 0;
        }
      } else {
        if (typeof value === 'string') {
          originAnswerNum += value.length;
        } else {
          // 特殊处理错误数据
          originAnswerNum += value.value ? value.value.length : 0;
        }
      }
    });
  });

  return { promptNum, editAnswerNum, originAnswerNum };
};

export const sortCountOriginContentNum = (
  statisticsData: any,
  valueList: any
): any => {
  let result: any = [];
  // 数据不存在 初始化数据
  if (!statisticsData) {
    return null;
    // const { promptNum, editAnswerNum, originAnswerNum } =
    //   countSortTaskNum(valueList);
    // result = [
    //   {
    //     component: '',
    //     terms: '',
    //     type: '',
    //     unit: 'number',
    //     name: '修改前的数据',
    //     num: promptNum + originAnswerNum,
    //     label: '修改前字符数量',
    //     fromNum: 0
    //   },
    //   {
    //     component: '',
    //     terms: '',
    //     type: '',
    //     unit: 'number',
    //     name: '修改后的数据',
    //     num: promptNum + originAnswerNum + editAnswerNum,
    //     label: '修改后字符数量',
    //     fromNum: 0
    //   }
    // ];
    // return JSON.stringify(result);
  }

  // 有历史数据 重新计算
  const preStatisticsData = JSON.parse(statisticsData);
  const changeBeforeIdx = preStatisticsData.findIndex(
    (item: any) => item.name === '修改前的数据'
  );

  const changeAfterIdx = preStatisticsData.findIndex(
    (item: any) => item.name === '修改后的数据'
  );

  if (preStatisticsData[changeAfterIdx]) {
    const { promptNum, editAnswerNum, originAnswerNum } =
      countSortTaskNum(valueList);
    preStatisticsData[changeBeforeIdx].num = promptNum + originAnswerNum;
    preStatisticsData[changeBeforeIdx].fromNum = promptNum + originAnswerNum;
    preStatisticsData[changeBeforeIdx].label = '修改前字符数量';
    preStatisticsData[changeAfterIdx].label = '修改后字符数量';
    const { num = 0 } = preStatisticsData[changeAfterIdx];
    preStatisticsData[changeAfterIdx].fromNum = num;
    preStatisticsData[changeAfterIdx].num =
      promptNum + originAnswerNum + editAnswerNum;
  }

  result = preStatisticsData;

  return JSON.stringify(result);
};

// 计算原始内容
const countTalkTaskNum = (valueList: any): any => {
  let promptNum = 0;
  let originAnswerNum = 0;
  let editAnswerNum = 0;
  valueList.conversation.forEach((conversationItem: any) => {
    const {
      prompt,
      response,
      isNew,
      rewrite = '',
      rewriteQuestion = '',
      kindType
    } = conversationItem;

    if (isNew === undefined && kindType === undefined) {
      // 原始数据
      // 原始问题求和
      promptNum += prompt.length;
      const responseArray: any = Object.values(response);
      const [originAnswerText = ''] = responseArray;
      // 原始答案相加
      originAnswerNum += originAnswerText.length;

      // 修改内容相加
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      editAnswerNum += rewrite.length + rewriteQuestion.length;
    } else if (isNew || (isNew && kindType === 2)) {
      // 自主增加的问和答
      const responseArray = Object.values(response);
      const originAnswerText: any = responseArray[0];
      const addQue = rewriteQuestion || prompt;
      const addAnswer = rewrite || originAnswerText;

      editAnswerNum += addQue.length + addAnswer.length;
    } else if (kindType === 3) {
      // 在线获取的数据
      const responseArray = Object.values(response);
      const originAnswerText: any = responseArray[0];
      const addQue = rewriteQuestion || prompt;
      const addAnswer = rewrite || originAnswerText;

      editAnswerNum += addQue.length + addAnswer.length;
    }
  });

  return { promptNum, editAnswerNum, originAnswerNum };
};

export const talkCountOriginContentNum = (
  statisticsData: any,
  valueList: any
): any => {
  let result: any = [];
  // 数据不存在 初始化数据
  if (!statisticsData) {
    return null;
    // const { promptNum, editAnswerNum, originAnswerNum } =
    //   countTalkTaskNum(valueList);
    // result = [
    //   {
    //     component: '',
    //     terms: '',
    //     type: '',
    //     unit: 'number',
    //     name: '修改前的数据',
    //     num: promptNum + originAnswerNum,
    //     label: '修改前字符数量',
    //     fromNum: 0
    //   },
    //   {
    //     component: '',
    //     terms: '',
    //     type: '',
    //     unit: 'number',
    //     name: '修改后的数据',
    //     num: promptNum + originAnswerNum + editAnswerNum,
    //     label: '修改后字符数量',
    //     fromNum: 0
    //   }
    // ];
    // return JSON.stringify(result);
  }

  // 有历史数据 重新计算
  const preStatisticsData = JSON.parse(statisticsData);
  const changeBeforeIdx = preStatisticsData.findIndex(
    (item: any) => item.name === '修改前的数据'
  );

  const changeAfterIdx = preStatisticsData.findIndex(
    (item: any) => item.name === '修改后的数据'
  );
  if (preStatisticsData[changeAfterIdx]) {
    const { promptNum, editAnswerNum, originAnswerNum } =
      countTalkTaskNum(valueList);
    preStatisticsData[changeBeforeIdx].num = promptNum + originAnswerNum;
    preStatisticsData[changeBeforeIdx].fromNum = promptNum + originAnswerNum;
    preStatisticsData[changeBeforeIdx].label = '修改前字符数量';
    preStatisticsData[changeAfterIdx].label = '修改后字符数量';
    const { num = 0 } = preStatisticsData[changeAfterIdx];
    preStatisticsData[changeAfterIdx].fromNum = num;
    preStatisticsData[changeAfterIdx].num =
      promptNum + originAnswerNum + editAnswerNum;

    result = preStatisticsData;
  }

  return JSON.stringify(result);
};

export const Cos = new COS({
  getAuthorization: (options, callback) => {
    const url = `${baseURL}/platform/dataSet/getCosInfo`; // url 替换成您自己的后端服务
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    // @ts-ignore
    xhr.setRequestHeader('Authorization', localStorage.getItem('token'));
    // eslint-disable-next-line consistent-return
    xhr.onload = (e: any) => {
      const data = JSON.parse(e.target.responseText);
      const credentials = data.data;
      if (!data || !credentials) {
        return console.error(
          `credentials invalid:\n${JSON.stringify(data, null, 2)}`
        );
      }
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        SecurityToken: credentials.sessionToken,
        StartTime: credentials.startTime,
        ExpiredTime: credentials.expiredTime
      });
    };
    xhr.send();
  }
});

// 格式化数据来源展示
export const formatMateriaUrl = (obj: any): string => {
  const {
    materialName = '未命名',
    appendUrls = '[]',
    materialUrl,
    materialKind = SELECT_URL_KIND_NUM.INPUT
  } = obj;
  const arr = getValidAppendUrls(appendUrls);
  const appendNames = arr.map((item: any) =>
    getShowUploadInfo(item.kind, item.url, item.name)
  );
  const firstCreateName = getShowUploadInfo(
    materialKind,
    materialUrl,
    materialName
  );
  return appendNames.length === 0
    ? `${firstCreateName}`
    : `${firstCreateName}</br>${appendNames.join('</br>')}`;
};

// 获取可以展示的上传信息
export const getShowUploadInfo = (
  kind: number,
  url: string,
  name: string
): string => {
  if (kind === SELECT_URL_KIND_NUM.INPUT) return url;
  return name;
};

export const getValidAppendUrls = (urls: any): any => {
  if (urls === null || urls === '[]' || urls === undefined) return [];
  if (typeof urls === 'string') return JSON.parse(urls);
  return urls;
};

// 后缀转类型num
export const suffixToTypeNum = (url: string): number => {
  const suffixName = getUrlEndSuffix(url).toLowerCase();
  // @ts-ignore
  return SUFFIX_NAME_TO_NUM[suffixName];
};

// 获取url结尾后缀
export const getUrlEndSuffix = (url: string): string => {
  if (!url) return '';
  const urlSplitArray = url.split('.');
  return urlSplitArray[urlSplitArray.length - 1];
};

// 复制
export const copyText = (
  text: string,
  successCb?: any,
  errorCb?: any
): void => {
  // 剪切板API
  const clipBoardApi = navigator.clipboard;
  // 复制的内容
  if (clipBoardApi) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success('复制成功');
        successCb && successCb();
      })
      .catch(() => {
        console.log('浏览器支持 但是异常');
        message.error('复制失败');
        errorCb && errorCb();
      });
  } else {
    console.log('浏览器不支持该方法');
    message.error('复制失败');
    errorCb && errorCb();
  }
};

export const confirmChangeData = (successFn: any) => {
  confirm({
    title: '确定要修改本题吗？',
    zIndex: 1004,
    onOk() {
      successFn();
    }
  });
};

export type EventType =
  | 'golabel_enter_exposure'
  | 'golabel_skip_click'
  | 'golabel_submit_click'
  | 'golabel_questionenquiry_click'
  | 'golabel_questionenquiry_exposure'
  | 'golabel_questionedit_click'
  | 'golabel_questionedit_exposure'
  | 'golabel_questionedit_save_click'
  | 'golabel_questionedit_reset_click'
  | 'golabel_questionedit_finish_click'
  | 'golabel_questionedit_close_click'
  | 'golabel_answeredit_click'
  | 'goinspection_answeredit_click'
  | 'gocheck_answeredit_click'
  | 'golabel_answeredit_exposure'
  | 'goinspection_answeredit_exposure'
  | 'gocheck_answeredit_exposure'
  | 'golabel_answeredit_save_click'
  | 'golabel_answeredit_reset_click'
  | 'golabel_answeredit_finish_click'
  | 'golabel_answeredit_close_click'
  | 'golabel_answeredit_Formula_click'
  | 'golabel_answeredit_calculate_click'
  | 'golabel_answeredit_symbol_click'
  | 'golabel_ocr_click'
  | 'no_movement'
  | 'goinspection_enter_exposure'
  | 'goinspection2_enter_exposure'
  | 'golabel_historydata_click'
  | 'gocheck_historydata_click'
  | 'golabel_baddata_click'
  | 'goinspection_baddata_click'
  | 'goinspection2_baddata_click'
  | 'gocheck_baddata_click'
  | 'goinspection_skip_click'
  | 'goinspection2_skip_click'
  | 'goinspection_fail_click'
  | 'goinspection2_fail_click'
  | 'goinspection_pass_click'
  | 'goinspection2_pass_click'
  | 'gocheck_pass_click'
  | 'gocheck_enter_exposure'
  | 'gocheck_repulse_click'
  | 'gocheck_last_click'
  | 'gocheck_next_click'
  | 'golabel_answeredit_Mark_click'
  | 'golabel_answeredit_open_click'
  | 'golabel_answeredit_plus_click'
  | 'golabel_doubt_click'
  | 'gofix_enter_exposure'
  | 'gofix_doubt_click'
  | 'gofix_baddata_click'
  | 'gofix_preview_click'
  | 'gofix_last_click'
  | 'gofix_next_click'
  | 'gofix_questionenquiry_click'
  | 'gofix_questionenquiry_exposure'
  | 'goinspection_answeredit_Formula_click'
  | 'gocheck_answeredit_Formula_click'
  | 'goinspection_answeredit_open_click'
  | 'gocheck_answeredit_open_click'
  | 'goinspection_answeredit_plus_click'
  | 'gocheck_answeredit_plus_click'
  | 'goinspection_answeredit_delete_click'
  | 'gocheck_answeredit_delete_click'
  | 'golabel_answeredit_delete_click'
  | 'gofix_preview_click'
  | 'golabel_preview_click'
  | 'goinspection_preview_click'
  | 'goinspection2_preview_click'
  | 'gocheck_preview_click'
  // 批注操作的eventID
  | 'goinspection_comment_create'
  | 'goinspection2_comment_create'
  | 'goinspection_comment_save'
  | 'goinspection2_comment_save'
  | 'goinspection_comment_cancel'
  | 'goinspection2_comment_cancel'
  | 'goinspection_comment_edit'
  | 'goinspection2_comment_edit'
  | 'goinspection_comment_delete'
  | 'goinspection2_comment_delete'
  | 'gocheck_comment_create'
  | 'gocheck_comment_save'
  | 'gocheck_comment_cancel'
  | 'gocheck_comment_edit'
  | 'gocheck_comment_delete'
  // todo
  | 'golabel_comment_area_show'
  | 'golabel_comment_area_open'
  | 'golabel_comment_area_close'
  // todo end
  | 'golabel_historydata_comment_area_show'
  | 'golabel_historydatacomment_area_open'
  | 'golabel_historydatacomment_area_close'
  | 'golabel_modify_comment_area_show'
  | 'golabel_modify_comment_area_open'
  | 'golabel_modify_comment_area_close'
  | 'golabel_alldata_comment_area_show'
  | 'golabel_alldata_comment_area_open'
  | 'golabel_alldata_comment_area_close'
  // 疑问数据上报日志
  | 'troubleshoot_data_report';

interface TrackingType {
  taskId: string;
  taskName: string;
  dataId: number;
  projectName: string;
  markType: string;
  markTypeName: string;
  nickname: string;
  userId: number;
  eventType: EventType;
  currentTaskNameID: string;
  currentDataID: string;
  dataNumber?: number;
  answerTab?: 'tab1' | 'tab2' | 'tab3' | 'tab4';
  functionType?: 'Mark' | 'Remove';
  OperatingType?: 'mouse' | 'Shortcut';
  saveType?: 'yes' | 'no';
  resetType?: 'yes' | 'no';
  confirmType?: 'yes' | 'no';
  closeType?: 'yes' | 'no';
  reMarkMsg?: string;
  submitType?: 1 | 2 | 3;
  isHide?: 1 | 0;
  commentType?: 1 | 2;
  operationType?: 1 | 2 | 3;
  uploadLogId?: string;
  projectInfo?: string;
  taskInfo?: string;
  userInfo?: string;
}

// 埋点
export const Tracking = async (trackingData: TrackingType): Promise<void> => {
  const {
    taskId,
    taskName,
    dataId,
    projectName,
    markType,
    markTypeName,
    nickname,
    userId,
    eventType,
    currentTaskNameID,
    currentDataID,
    OperatingType,
    saveType,
    resetType,
    confirmType,
    closeType,
    reMarkMsg,
    dataNumber,
    functionType,
    answerTab,
    submitType,
    isHide,
    commentType,
    projectInfo,
    taskInfo,
    uploadLogId,
    userInfo
  } = trackingData;
  const logTime = getCurrentDateTime();
  const commonData = {
    taskId,
    taskName,
    dataId,
    projectName,
    markType,
    markTypeName,
    nickname,
    userId,
    logTime
  };
  const eventData: any = {
    event_type: eventType,
    current_taskName_ID: taskId,
    current_data_ID: dataId
  };
  switch (eventType) {
    case 'troubleshoot_data_report':
      eventData.project_info = projectInfo;
      eventData.upload_log_id = uploadLogId;
      eventData.user_info = userInfo;
      eventData.task_info = taskInfo;
      break;
    case 'golabel_skip_click':
    case 'golabel_questionedit_click':
    case 'golabel_answeredit_click':
    case 'goinspection_answeredit_click':
    case 'gocheck_answeredit_click':
    case 'golabel_ocr_click':
      eventData.Operating_type = OperatingType;
      break;
    case 'golabel_submit_click':
      eventData.Operating_type = OperatingType;
      eventData.submit_type = submitType;
      break;
    case 'golabel_questionedit_save_click':
    case 'golabel_answeredit_save_click':
      eventData.save_type = saveType;
      if (reMarkMsg) eventData.reMarkMsg = reMarkMsg;
      eventData.Operating_type = OperatingType;
      break;
    case 'golabel_questionedit_reset_click':
    case 'golabel_answeredit_reset_click':
      eventData.reset_type = resetType;
      if (reMarkMsg) eventData.reMarkMsg = reMarkMsg;
      break;
    case 'golabel_questionedit_finish_click':
    case 'golabel_answeredit_finish_click':
      eventData.confirm_type = confirmType;
      if (reMarkMsg) eventData.reMarkMsg = reMarkMsg;
      eventData.Operating_type = OperatingType;
      break;
    case 'golabel_questionedit_close_click':
    case 'golabel_answeredit_close_click':
      eventData.close_type = closeType;
      if (reMarkMsg) eventData.reMarkMsg = reMarkMsg;
      eventData.Operating_type = OperatingType;
      break;
    case 'golabel_answeredit_Formula_click':
    case 'goinspection_answeredit_Formula_click':
    case 'gocheck_answeredit_Formula_click':
    case 'goinspection_comment_create':
    case 'gocheck_comment_create':
    case 'goinspection2_comment_create':
      eventData.data_number = dataNumber;
      eventData.Operating_type = OperatingType;
      break;
    case 'golabel_answeredit_Mark_click':
      eventData.function_type = functionType;
      eventData.data_number = dataNumber;
      break;
    case 'golabel_answeredit_calculate_click':
    case 'golabel_answeredit_symbol_click':
    case 'gofix_preview_click':
    case 'golabel_preview_click':
    case 'goinspection_preview_click':
    case 'goinspection2_preview_click':
    case 'gocheck_preview_click':
    case 'golabel_comment_area_open':
    case 'golabel_historydatacomment_area_open':
    case 'golabel_modify_comment_area_open':
    case 'golabel_alldata_comment_area_open':
      eventData.data_number = dataNumber;
      break;
    case 'golabel_answeredit_plus_click':
    case 'goinspection_answeredit_plus_click':
    case 'gocheck_answeredit_plus_click':
    case 'goinspection_answeredit_delete_click':
    case 'gocheck_answeredit_delete_click':
    case 'golabel_answeredit_delete_click':
      // eventData.data_number = dataNumber;
      eventData.answer_tab = answerTab;
      break;
    case 'golabel_answeredit_open_click':
    case 'goinspection_answeredit_open_click':
    case 'gocheck_answeredit_open_click':
      // eventData.data_number = dataNumber;
      eventData.answer_tab = answerTab;
      eventData.is_hide = isHide;
      break;
    case 'goinspection_skip_click':
    case 'goinspection2_skip_click':
    case 'gocheck_last_click':
    case 'gocheck_next_click':
    case 'gofix_last_click':
    case 'gofix_next_click':
      eventData.Operating_type = OperatingType;
      break;
    case 'goinspection_comment_edit':
    case 'goinspection2_comment_edit':
    case 'goinspection_comment_delete':
    case 'goinspection2_comment_delete':
    case 'gocheck_comment_edit':
    case 'gocheck_comment_delete':
      eventData.data_number = dataNumber;
      eventData.comment_type = commentType;
      break;
    case 'goinspection_comment_save':
    case 'goinspection2_comment_save':
    case 'gocheck_comment_save':
    case 'goinspection_comment_cancel':
    case 'goinspection2_comment_cancel':
    case 'gocheck_comment_cancel':
      eventData.data_number = dataNumber;
      eventData.comment_type = commentType;
  }

  const allData = {
    ...commonData,
    ...eventData
  };

  const client = new AsyncClient({
    endpoint: 'https://ap-beijing.cls.tencentcs.com',
    retry_times: 10
  });

  const logGroup = new LogGroup('https://ap-beijing.cls.tencentcs.com');
  logGroup.setSource('https://ap-beijing.cls.tencentcs.com');

  const log = new Log(Date.now());
  Object.entries(allData).forEach(([key, value]) => {
    log.addContent(key, String(value));
  });

  logGroup.addLog(log);

  const onlineUrlArray = [
    'admin.vegas.100tal.com',
    'label.vegas.100tal.com',
    'client.vegas.100tal.com',
    'admin.datasmarker.com',
    'label.datasmarker.com',
    'client.datasmarker.com',
    'label-datasmarker.facethink.com'
    // 'label.vegas.facethink.com',
    // 'admin.vegas.facethink.com',
    // 'client.vegas.facethink.com'
  ];

  let isProd = false;
  if (onlineUrlArray.includes(window.location.host)) {
    isProd = true;
  }

  const id = isProd
    ? '904f15b8-19e5-42ac-ab6f-b8c0fc22ef56' // 线上
    : '1c68f1e7-3c59-4cd3-8265-0331ffa64d9a'; // 线下

  const request = new PutLogsRequest(id, logGroup);
  const data = await client.PutLogs(request);
};

function getCurrentDateTime() {
  const date = new Date();
  const y = date.getFullYear();
  let m: string | number = date.getMonth() + 1;
  m = m < 10 ? `0${m}` : m;
  let d: string | number = date.getDate();
  d = d < 10 ? `0${d}` : d;
  let h: string | number = date.getHours();
  h = h < 10 ? `0${h}` : h;
  let minute: string | number = date.getMinutes();
  minute = minute < 10 ? `0${minute}` : minute;
  let second: string | number = date.getSeconds();
  second = second < 10 ? `0${second}` : second;
  let millisecond: string | number = date.getMilliseconds();
  if (millisecond < 10) {
    millisecond = `00${millisecond}`;
  } else if (millisecond < 100) {
    millisecond = `0${millisecond}`;
  }
  return `${y}-${m}-${d} ${h}:${minute}:${second}.${millisecond}`;
}

const getUserAnnotateOriginText = (nodeList: any, parentNode: any): any => {
  // 对标住员标注的数据做单独处理
  let array = [];
  let curText = '';
  const nodeArrayList: any = Array.from(nodeList);
  for (let i = 0; i < nodeArrayList.length; i++) {
    const item = nodeArrayList[i];
    // 换行
    if (item.nodeName === 'BR') {
      array.push(curText);
      curText = '';
    } else if (
      // 非可选项
      item.nodeName === 'SPAN' &&
      item.className.indexOf('no-select') !== -1
    ) {
      // eslint-disable-next-line no-continue
      continue;
    } else if (
      // latex公式
      item.nodeName === 'SPAN' &&
      item.className.indexOf('latex-block') !== -1
    ) {
      curText += `$$${decodeURIComponent(item.dataset.originLatex)}$$`;
    } else if (
      // 图片
      item.nodeName === 'SPAN' &&
      item?.childNodes &&
      item?.childNodes[0] &&
      item?.childNodes[0].nodeName === 'IMG'
    ) {
      curText += item?.childNodes[0].outerHTML;
    } else if (
      // 编辑器中的标注 暂时只取标红的原始文本
      item.nodeName === 'SPAN' &&
      item?.childNodes &&
      item?.childNodes[0].nodeName !== '#text' &&
      item?.childNodes[0]?.className?.indexOf('marker-red') !== -1
    ) {
      // curText += item?.childNodes[0].outerHTML;
      curText += item?.innerText;
    } else if (
      // 换行
      item.nodeName === 'SPAN' &&
      item?.innerText === '\n'
    ) {
      array.push(curText);
      curText = '';
    } else if (
      // 表格--暂时忽略
      item.nodeName === 'SPAN' &&
      item?.childNodes &&
      item?.childNodes[0].nodeName !== '#text' &&
      item?.childNodes[0]?.className?.indexOf('table-container') !== -1
    ) {
      // eslint-disable-next-line no-continue
      continue;
    } else {
      // console.log('item', item, item.innerText);
      curText += item.innerText;
    }

    if (i === nodeArrayList.length - 1) {
      array.push(curText);
      curText = '';
    }
  }
  return array;
};

// 通过node节点获取原始文本
const getNodeToOriginText = (nodeList: any, parentNode?: any): any => {
  const resultArray: any = [];

  // 获取有效数据，过滤nodeList中 是数学公式内的text节点
  const formatNodeList = Array.from(nodeList).filter((item: any) => {
    return (
      !(
        (item.nodeName === '#text' &&
          item.namespaceURI &&
          item.namespaceURI.indexOf('MathML') === -1) ||
        (item.nodeName === '#text' && item.nodeValue === '\n  ')
      ) &&
      // 如果想让哪个元素不能复制 加 data-copy="no" 属性
      (item.getAttribute === undefined ||
        item.getAttribute('data-copy') !== 'no')
    );
  });
  for (let i = 0; i < formatNodeList.length; i++) {
    const item: any = formatNodeList[i];
    const nextItem = formatNodeList[i + 1];
    const preItem: any = formatNodeList[i - 1] || '';
    const isOriginTextNode = item.nodeName === '#text';
    const isHasChildNodes = item.childNodes.length > 1;
    const isNodeSpan = item.nodeName === 'SPAN';
    const isNodeDiv = item.nodeName === 'DIV';
    // 原始文本节点并且是非数学公式的文本节点
    if (
      (isOriginTextNode && !item.namespaceURI) ||
      (isOriginTextNode && item.namespaceURI.indexOf('MathML') === -1)
    ) {
      // 如果上一项数据是非换行项就进行拼接逻辑 不进行新插入逻辑
      if (i > 0) {
        if (
          preItem &&
          !(
            preItem.nodeName === 'BR' ||
            preItem.nodeValue === '\n' ||
            preItem.nodeName === 'P' ||
            preItem.className === 'DIV'
          )
        ) {
          let preVal: any = resultArray.pop();
          preVal += item.nodeValue;
          resultArray.push(preVal);
        }
      } else {
        // 首项默认插入
        resultArray.push(item.nodeValue);
      }
      // 针对提问的元素文本节点做扩展
    } else if (
      isHasChildNodes &&
      item?.className?.indexOf('ask-inner') !== -1 &&
      isNodeSpan
    ) {
      const spanNodeResult = getNodeToOriginText(item.childNodes);
      resultArray.push(spanNodeResult.join(''));
      // 针对回答的元素文本节点做扩展
    } else if (
      isHasChildNodes &&
      item?.className?.indexOf('ask-inner') !== -1 &&
      !isNodeSpan
    ) {
      resultArray.push(item.innerText);
    } else if (
      item.nodeName === 'SPAN' &&
      item?.className?.indexOf('mathtex-block') !== -1
    ) {
      // 回复答案中的内容
      const customLatex = item.getAttribute('data-origin-latex');
      resultArray.push(`$$${decodeURIComponent(customLatex)}$$`);
    } else if (
      // 文本批改组件中 高亮的部分内容纯文本
      item.nodeName === 'SPAN' &&
      item?.className?.indexOf('span-item') !== -1 &&
      item?.className?.indexOf('latex-block') === -1
    ) {
      const lastIdx = resultArray.length - 1 < 0 ? 0 : resultArray.length - 1;
      if (resultArray[lastIdx]) {
        resultArray[lastIdx] += item.innerText;
      } else {
        resultArray[lastIdx] = '' + item.innerText;
      }
    } else if (
      item.nodeName === 'SPAN' &&
      item?.className?.indexOf('span-item') !== -1 &&
      item?.className?.indexOf('latex-block') !== -1
    ) {
      const lastIdx = resultArray.length - 1 < 0 ? 0 : resultArray.length - 1;

      // 文本批改组件中 高亮的部分内容latex
      const customLatex = item.getAttribute('data-origin-latex');
      if (resultArray[lastIdx]) {
        resultArray[lastIdx] += `$$${decodeURIComponent(customLatex)}$$`;
      } else {
        resultArray[lastIdx] = '' + `$$${decodeURIComponent(customLatex)}$$`;
      }
    } else if (isNodeDiv && item?.className?.indexOf('ask-inner') !== -1) {
      resultArray.push(item.innerText);
      // 如果是数学公式的一部分
    } else if (
      item.namespaceURI &&
      item.namespaceURI.indexOf('MathML') !== -1
    ) {
      // 最后一项是数学公式的一部分
      if (!nextItem) {
        console.warn('最后一块是数学公式', parentNode);
        let latexParentNode = parentNode ?? item.parentNode;
        const customLatex = latexParentNode.getAttribute('data-origin-latex');
        resultArray.push(`$$${decodeURIComponent(customLatex)}$$`);
      }
    } else if (item.nodeName === 'IMG') {
      resultArray.push(item.outerHTML);
      // 过滤非可选项和只有\n的标签 此处兼容有序打分自定义模版选中多个元素复制时造成的异常
    } else if (
      (item &&
        item?.className &&
        item?.className?.indexOf &&
        item?.className?.indexOf('no-select') !== -1) ||
      item.innerHTML === '\n'
    ) {
      console.log('no select', item);
    } else if (
      item.nodeName === 'SPAN' &&
      item?.className?.indexOf('latex-block') !== -1
    ) {
      const customLatex = item.getAttribute('data-origin-latex');
      if (resultArray.length) {
        let preValue: any = resultArray.pop();
        preValue += `$$${decodeURIComponent(customLatex)}$$`;
        resultArray.push(preValue);
      } else {
        resultArray.push(`$$${decodeURIComponent(customLatex)}$$`);
      }
    } else {
      const otherNode = getNodeToOriginText(item.childNodes);
      resultArray.push(otherNode.join(''));
    }
  }
  return resultArray;
};

// 获取数学标签根节点
export const getRootNode = (node: any): unknown => {
  if (node.namespaceURI && node.namespaceURI.indexOf('MathML') !== -1) {
    return getRootNode(node.parentNode);
  }
  return node;
};

export const getSpanPosNode = (node: any): any => {
  if (!node) return;
  if (node?.dataset?.pos) {
    // eslint-disable-next-line consistent-return
    return node;
  }
  // eslint-disable-next-line consistent-return
  return getSpanPosNode(node.parentNode);
};

// 获取划选的内容
const getGptSelectionValue = () => {
  const selected: any = window.getSelection();
  // 未选中内容时直接返回
  if (selected.type === 'None' || selected.type === 'Caret') return '';
  // 起始点和结束点相同时直接返回
  if (selected.isCollapsed) return '';
  let copyTextArray = [];
  // 获取rangeCount数量 有内容时处理
  if (selected.rangeCount > 0) {
    const range = selected.getRangeAt(0);
    const fragment = range.cloneContents();
    let parentElement: any = range.commonAncestorContainer;

    // 如果选中内容跨越多个元素，则 commonAncestorContainer 可能会返回 Text 节点，
    // 所以我们需要确保获取到其父元素
    if (parentElement.nodeType !== Node.ELEMENT_NODE) {
      parentElement = parentElement.parentNode;
    }

    // 获取元素最近的根节点
    const rootNode: any = getRootNode(parentElement);

    // 现在你可以遍历 fragment 中的所有节点
    const childNodes = fragment.childNodes;
    if (rootNode?.className?.indexOf('answer-content-value') !== -1) {
      copyTextArray = getUserAnnotateOriginText(childNodes, rootNode);
    } else {
      copyTextArray = getNodeToOriginText(childNodes, rootNode);
    }
    copyTextArray = copyTextArray.map((item: any, index: any) => {
      if (index !== copyTextArray.length - 1) {
        return `${item}\n`;
      }
      return item;
    });
  }
  return copyTextArray;
};

const commonCopyText = (textToCopy: string) => {
  const copys = Object.keys(localStorage).filter((item) => {
    return item?.startsWith('copy');
  });
  copys.forEach((key) => {
    localStorage.removeItem(key);
  });
  const textarea = document.createElement('textarea');
  const uid = `copy-${uuidv1()}`;
  localStorage.setItem(uid, textToCopy);
  textarea.value = uid;
  textarea.style.position = 'fixed';
  textarea.style.top = '-9999px';
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};

const checkParentClass = (element: any): boolean => {
  // 当元素存在且有父元素时执行
  if (element && element.parentElement) {
    // 检查父元素类是否包含'ck'
    if ((Array.from(element.parentElement?.classList) || []).includes('ck')) {
      return true;
    }
    // 如果不包含，则向上递归
    return checkParentClass(element.parentElement);
  }

  // 如果没有更多的父元素或者传入的元素不存在，则返回false
  return false;
};

export const addCopyCallback = (e: any) => {
  // 兼容在编辑器中复制时 不进行数据处理依赖编辑器内部的复制逻辑
  if (checkParentClass(e?.target)) {
    return;
  }
  // e.preventDefault();
  const selectionText = getGptSelectionValue();
  if (!selectionText) return;
  copyText(selectionText.join(''));
  // commonCopyText(selectionText.join(''));
};
export const getOperatingSystem = (): 'MacOS' | 'Windows' | 'Not known' => {
  let operatingSystem: 'MacOS' | 'Windows' | 'Not known' = 'Not known';

  if (window.navigator.userAgent.indexOf('Windows') != -1) {
    operatingSystem = 'Windows';
  } else if (window.navigator.userAgent.indexOf('Mac') != -1) {
    operatingSystem = 'MacOS';
  }

  return operatingSystem;
};

// 判断是什么操作进入的什么页面
export const useGetToFromPage = () => {
  const type = useRef<
    | 'Mark_ToMark'
    | 'Inspect_FirstToInspect'
    | 'Inspect_SecondToInspect'
    | 'Check_ToCheck'
    | 'Modify_ToModify'
    | 'Mark_View'
    | ''
  >('');
  const { inspectType, stageType } = useParams<{
    inspectType: string;
    stageType: string;
  }>();
  useEffect(() => {
    const pathname = window.location.pathname;
    const search = new URLSearchParams(window.location.search);
    const isMark = pathname.includes('/other-mark/conversation-rewrite');
    const isModify = pathname.includes('/other-modify/conversation-rewrite/');
    const isInspect = pathname.includes(
      '/annotation-detail/inspect-conversation-rewrite/'
    );
    const isCheck = pathname.includes('/other-pre-view/conversation-rewrite');
    // 判断是否是去标注页面
    if (isMark && search.get('status') === null) {
      // 去标注页面
      type.current = 'Mark_ToMark';
    } else if (isMark && search.get('status') === '4') {
      // 标注平台查看数据
      type.current = 'Mark_View';
    } else if (isModify) {
      // 去修改页面
      type.current = 'Modify_ToModify';
    } else if (isInspect && inspectType === '2') {
      // 第一次去质检页面
      type.current = 'Inspect_FirstToInspect';
    } else if (isInspect && inspectType === '12') {
      // 第二次去质检页面
      type.current = 'Inspect_SecondToInspect';
    } else if (
      isCheck &&
      stageType === 'Check' &&
      search.get('status') === null
    ) {
      // 去验收
      type.current = 'Check_ToCheck';
    }
  }, []);
  return type.current;
};

const emptyShortcutTip =
  '本期新增快捷键与已设置的快捷键冲突，系统已清空原有快捷键，请尽快进行修改';

// 在原来的需求上修改快捷键和新增快捷键的操作
export const handleModifyAddShortcut = (
  taskId: string
): Promise<Record<string, any>> => {
  // 设置快捷键默认值
  let shortcutData = shortcut;
  let shortcutDataTemp: undefined | Record<string, any>;
  return new Promise((resolve) => {
    queryShortcutKyes({ taskId }).then((data: any) => {
      // 判断是否已经设置过快捷键
      if (!data?.data?.content) {
        // 没有设置过快捷键就将默认快捷键储存并返回默认快捷键
        // empty参数表示是否存在没有设置的快捷键，如果为true就使用tip的提示
        addShortcutKyes({ taskId, content: shortcutData });
        resolve({
          data: JSON.parse(shortcutData),
          empty: false,
          tip: emptyShortcutTip
        });
      } else {
        shortcutDataTemp = JSON.parse(data.data.content);
      }

      // 如果快捷键已经是最新的了就直接返回接口的相应值，通过newestTime字段判断是否最新，newestTime为Shortcut.ts文件中自己设置的，每次变更或新增快捷键都需要修改newestTime的值
      // 判断是否已经更新过了
      if (shortcutDataTemp?.newestTime === newestTime) {
        resolve({
          data: shortcutDataTemp,
          empty: emptyShortcut(shortcutDataTemp),
          tip: emptyShortcutTip
        });
      }

      // vegas 1.9.6版本需求
      /**
       * 1. 将“编辑”修改成“展开/收起编辑”
       * 2. 增加“确定”快捷键
       * 3. 增加“批注唤起”快捷键
       */
      // 确定的快捷键是否存在
      let commonShortcutArrC = false;
      // 批注唤起的快捷键是否存在
      let commonShortcutArrQ = false;
      // 判断快捷键是否设置了“编辑”还是“展开/收起编辑”，如果设置的是“编辑”就将名称改成“展开/收起编辑”
      (shortcutDataTemp as Record<string, any>).commonShortCutArr =
        shortcutDataTemp?.commonShortCutArr.reduce(
          (pre: Record<string, any>, cur: Record<string, any>) => {
            // 1.9.6 start
            if (cur.name === '编辑') cur.name = '展开/收起编辑';
            if (cur.name === '确定') commonShortcutArrC = true;
            if (cur.name === '批注唤起') commonShortcutArrQ = true;
            // 1.9.6 end
            pre.push(cur);
            return pre;
          },
          []
        );

      // 1.9.6 start
      // 如果没有“确定”快捷键和“批注唤起”快捷键，因为“确定”快捷键和“批注唤起”快捷键是一个版本新增的需求，所以可以写在一起
      if (!commonShortcutArrC && !commonShortcutArrQ) {
        // 将快捷键c和q清空
        (shortcutDataTemp as Record<string, any>).commonShortCutArr =
          shortcutDataTemp?.commonShortCutArr.reduce(
            (pre: Record<string, any>, cur: Record<string, any>) => {
              // 将已经设置过了快捷键清空，避免冲突
              if (cur.secondShortCut === 'q') {
                cur.secondShortCut = '';
              }
              // 将已经设置过了快捷键清空，避免冲突
              if (cur.secondShortCut === 'c') {
                cur.secondShortCut = '';
              }
              pre.push(cur);
              return pre;
            },
            []
          );
        (shortcutDataTemp as Record<string, any>).numberSignArr =
          shortcutDataTemp?.numberSignArr.reduce(
            (pre: Record<string, any>, cur: Record<string, any>) => {
              // 将已经设置过了快捷键清空，避免冲突
              if (cur.secondShortCut === 'q') {
                cur.secondShortCut = '';
              }
              // 将已经设置过了快捷键清空，避免冲突
              if (cur.secondShortCut === 'c') {
                cur.secondShortCut = '';
              }
              pre.push(cur);
              return pre;
            },
            []
          );
        // 新增“确定”快捷键
        (shortcutDataTemp as Record<string, any>).commonShortCutArr.push({
          value: true,
          name: '确定',
          firstShortCut: 'alt',
          secondShortCut: 'c',
          default: false
        });
        // 新增“批注唤起”快捷键
        (shortcutDataTemp as Record<string, any>).commonShortCutArr.push({
          value: true,
          name: '批注唤起',
          firstShortCut: 'alt',
          secondShortCut: 'q',
          default: false
        });
      }
      // 1.9.6 end

      // 更新时间
      (shortcutDataTemp as Record<string, any>).newestTime = newestTime;
      addShortcutKyes({ taskId, content: JSON.stringify(shortcutDataTemp) });
      resolve({
        data: shortcutDataTemp as Record<string, any>,
        empty: emptyShortcut(shortcutDataTemp as Record<string, any>),
        tip: emptyShortcutTip
      });
    });
  });
};

// 判断是否有空的快捷键，如果有就进行提示“”
const emptyShortcut = (shortcutData: Record<string, any>): boolean => {
  const { commonShortCutArr, numberSignArr } = shortcutData;
  const commonShortCutArrN = commonShortCutArr.length;
  const numberSignArrN = numberSignArr.length;
  for (let i = 0; i < commonShortCutArrN; i++) {
    if (commonShortCutArr[i].secondShortCut === '') {
      return true;
    }
  }
  for (let i = 0; i < numberSignArrN; i++) {
    if (numberSignArr[i].secondShortCut === '') {
      return true;
    }
  }
  return false;
};

// 判断一个时间距离现在多少天数
export const distanceNowTime = (time: number): number => {
  const nowTime = new Date().getTime();
  const day = Math.floor((nowTime - time) / 1000 / 60 / 60 / 24);
  return day;
};

// 给定一个时间戳 获取这一天的开始的时间戳
export const startDayTime = (time: number): number => {
  const timeData = new Date(time);
  const startOfDat = new Date(
    timeData.getFullYear(),
    timeData.getMonth(),
    timeData.getDate(),
    0,
    0,
    0,
    0
  );
  return startOfDat.getTime();
};

export const getPDomChildText = (p: any) => {
  let fullText = '';
  const childList = Array.from(p?.childNodes);
  childList.forEach((item: any, index: number) => {
    if (item?.nodeName === '#text') {
      fullText += item.nodeValue;
    } else if (item?.nodeName === 'SPAN') {
      fullText += `$$${decodeURIComponent(item.dataset.originLatex)}$$`;
    }
    if (item?.nodeName === 'P') {
      item?.childNodes.forEach((childItem: any) => {
        if (childItem.nodeName === '#text') {
          fullText += childItem.nodeValue;
        } else if (childItem.nodeName === 'SPAN') {
          fullText += `$$${decodeURIComponent(
            childItem.dataset.originLatex
          )}$$`;
        } else if (childItem.nodeName === 'IMG') {
          fullText += childItem.currentSrc;
        }
      });
    }
    // 结尾时不添加换行
    if (index !== childList.length - 1) {
      fullText += '\n';
    }
  });
  return fullText;
};

const stripTags = (input: string) => {
  return input.replace(/<[^>]*>/g, '');
};

// 替换table数据
const replaceTableData = (content: string) => {
  let data = content;

  let regexStart = /\$\#(?:table\{)?[\n]*/g;
  let regexEnd = /[\n]*\}\#\$|[\n]*\#\$/g;
  // todo
  data = data.replace(regexStart, '%MARK_DOWN_START%');
  data = data.replace(regexEnd, '%MARK_DOWN_END%');

  // 将开头和结尾范围内的\n替换成%BR%
  data = data.replace(
    /(%MARK_DOWN_START%[\s\S]*?%MARK_DOWN_END%)/g,
    (match: any) => {
      return match.replace(/\n/g, '%BR%');
    }
  );
  return data;
};

export const formatNewText = (text: string) => {
  const content = text;
  let splitNewPart = content.split('%NEW_CHILD_PART%');

  let regex =
    // @ts-ignore
    // eslint-disable-next-line
    /(\$\$.*?\$\$|\$#.*?#\$|<img src=".*?">|<img src=".*?"\/>|<figure class="image">(.*?)<\/figure>|<mark[\s\S]*?<\/mark>|<mark[\s\S]*?>|<\/mark>|\$#table\{.*?\}#\$|%NEW_CHILD_PART%|%NEW_LINE%|%SPLIT_LINE%|\s|[^ ]+?)/gs;

  // let contentArrayRegs: any = content?.match(regex) || [];
  const array: any = [];
  splitNewPart.forEach((item: any, index: number) => {
    let contentArrayRegs: any = item?.match(regex) || [];
    for (let i = 0; i < contentArrayRegs.length; i++) {
      const curData = contentArrayRegs[i];
      if (
        (curData.startsWith('$$') && curData.endsWith('$$')) ||
        curData === '$'
      ) {
        const value = curData;
        const onlyKey = uuidv4();
        value.split('').forEach((char: any) => {
          array.push({
            value: char,
            type: SELECT_TYPE_LIST.LATEX,
            key: onlyKey
          });
        });
      } else if (curData.startsWith('$#') && curData.endsWith('#$')) {
        const value = curData;
        const onlyKey = uuidv4();
        value.split('').forEach((char: any) => {
          array.push({
            value: char,
            type: SELECT_TYPE_LIST.TABLE,
            key: onlyKey
          });
        });
      } else if (
        curData.startsWith('<figure') &&
        curData.endsWith('/figure>')
      ) {
        const value = curData;
        const onlyKey = uuidv4();
        value.split('').forEach((char: any) => {
          array.push({
            value: char,
            type: SELECT_TYPE_LIST.IMAGE,
            key: onlyKey
          });
        });
      } else if (
        curData.startsWith('<img src="') &&
        (curData.endsWith('/>') || curData.endsWith('>'))
      ) {
        const value = curData;
        const onlyKey = uuidv4();
        value.split('').forEach((char: any) => {
          array.push({
            value: char,
            type: SELECT_TYPE_LIST.IMAGE,
            key: onlyKey
          });
        });
      } else if (
        (curData.startsWith('<mark') && curData.endsWith('</mark>')) ||
        curData.startsWith('<mark') ||
        curData.endsWith('</mark>')
      ) {
        // const value = stripTags(curData);
        const value = curData;
        const onlyKey = uuidv4();
        value.split('').forEach((char: any) => {
          array.push({
            value: char,
            type: SELECT_TYPE_LIST.HIGH_LIGHT,
            key: onlyKey
          }); // stripTags is a function to remove HTML tags from string
        });
      } else if (curData.startsWith('$#table') && curData.endsWith('#$')) {
        const value = curData;
        const onlyKey = uuidv4();
        value.split('').forEach((char: any) => {
          array.push({
            value: char,
            type: SELECT_TYPE_LIST.TABLE,
            key: onlyKey
          });
        });
      } else if (curData === '\n') {
        array.push({
          value: '\n',
          type: SELECT_TYPE_LIST.NEW_LINE,
          key: uuidv4()
        });
      } else if (curData === '%NEW_CHILD_PART%') {
        array.push({
          value: '%NEW_CHILD_PART%',
          type: SELECT_TYPE_LIST.CHILD_PART,
          key: uuidv4()
        });
      } else if (curData === ' ') {
        array.push({
          value: ' ',
          type: SELECT_TYPE_LIST.SPACE,
          key: uuidv4()
        });
      } else {
        array.push({
          value: curData,
          type: SELECT_TYPE_LIST.TEXT,
          key: uuidv4()
        });
      }
    }

    // 插入新增子项节点
    if (index !== splitNewPart.length - 1) {
      array.push({
        value: '%NEW_CHILD_PART%',
        type: SELECT_TYPE_LIST.CHILD_PART,
        key: uuidv4()
      });
    }
  });

  return array;
};

// html转义符号替换
export const htmlEntitiesUtil = (text: string) => {
  if (!text) return '';
  let data = text;
  data = data.replace(regexEnities, (match: any) => htmlEntities[match]);
  return data;
};

// 获取latexdom元素 文本数据
export const getLatexDomString = (originLatex: string) => {
  if (!originLatex) {
    return { isCenter: false, latexString: '' };
  }
  const { isCenter, content } = findCenterLineContent(originLatex);
  let latexString = '';
  let latexText = content;
  latexText = htmlEntitiesUtil(latexText);

  try {
    let spaceLatexText: any = latexText.split(' ');
    spaceLatexText = spaceLatexText.join('\\text{ }\\text{ }');
    // @ts-ignore
    latexString = window.MathJax.tex2mml(
      (window as any).supportSpace ? spaceLatexText : latexText,
      {
        display: false
      }
    );
  } catch (error: any) {
    // @ts-ignore
    if (window.$Aegis) {
      // @ts-ignore
      window.$Aegis.report({
        msg: 'latex_error',
        ext1: latexText,
        ext2: error.message
      });
    }
  }
  return { isCenter, latexString };
};

// 获取table dom元素数据
export const getTableDomString = (originTable: string) => {
  if (!originTable) return '';
  // 清除开头和结尾的标识符号 只保留内容
  const clearStattAndEndTag = originTable
    .replace(/\$#/g, '')
    .replace(/#\$/, '');
  const tableContents = findTableContents(clearStattAndEndTag);
  let replaceTable = clearStattAndEndTag;

  if (tableContents.length > 0) {
    tableContents.forEach((tableStr) => {
      const regex = /^table{/;
      const isTableStart = regex.test(replaceTable);
      const value = isTableStart ? `table{${tableStr}}` : `${tableStr}`;
      replaceTable = replaceTable.replace(value, () => {
        tableStr = tableStr.replace(/%BR%/g, '\n');
        let rows = tableStr.split(/\|\n\|/g);
        rows = rows.map((item) =>
          item
            .replace(/\|\n/g, '|')
            .replace(/^\n|\n$/g, '')
            .replace(/\n/g, '%next%')
        );
        rows.forEach((item: string, index: number) => {
          if (!item?.startsWith('|')) {
            rows[index] = `|${rows[index]}`;
          }
          if (!item?.endsWith('|')) {
            rows[index] += '|';
          }
        });

        // 如果table数据自带分隔符则不进行 添加分隔符操作
        if (
          rows[1]?.startsWith('| - ') ||
          rows[1]?.startsWith('| - |') ||
          rows[1]?.startsWith('| -|') ||
          rows[1]?.startsWith('|-|')
        ) {
          return rows.join('\n');
        }

        // 没有分隔符的话 主动添加分隔符
        const headSimple = rows[0].split('|');
        headSimple.pop();
        headSimple.shift();
        let headLine = '';
        headSimple.forEach(() => {
          headLine += '|-';
        });
        headLine += '|';
        rows.splice(1, 0, headLine);
        return rows.join('\n');
      });
    });
  }

  let markdownText = replaceTable;

  markdownText = markdownText.replace(regexes.latex, (a: string, b: string) => {
    let { latexString } = getLatexDomString(b);

    // 清除markdown中latex标签的换行符 兼容markdown-it因为换行符导致的不渲染问题
    latexString = latexString?.replace(/\n/g, '');

    return `<span class="latex-block" data-origin-latex="${encodeURIComponent(
      b
    )}">${latexString}</span>`;
  });

  markdownText = md.render(markdownText);

  markdownText = markdownText
    .replace(/<table>/g, '<div class="table-container"><table>')
    .replace(/<\/table>/g, '</table></div>');

  markdownText = markdownText.replace(/%next%/g, '\n');
  return markdownText;
};

// 比较两个数组是否相同
export function isSubArray(a: any, b: any) {
  let aIndex = 0;
  let bIndex = 0;
  let startIndex = -1; // 增加一个变量来保存开始的索引

  while (bIndex < b.length) {
    if (a[aIndex].value === b[bIndex].value) {
      if (aIndex === 0) startIndex = bIndex; // 如果是a中第一个元素匹配，记录下当前b中的索引
      aIndex++;
      if (aIndex === a.length) return startIndex; // 如果已经找完a中所有元素，则返回开始的索引
    } else if (aIndex > 0) {
      // 如果出现不连续但之前有匹配过（即aIndex>0），则重置a索引和b索引回到上次开始匹配的位置
      bIndex -= aIndex;
      aIndex = 0;
    }
    bIndex++;
  }

  return -1;
}

// eslint-disable-next-line consistent-return
export const autoChangeSelectRange = (): any => {
  const selection: any = window.getSelection();
  // 没有选中内容
  if (selection?.isCollapsed) return {};
  // eslint-disable-next-line no-useless-return
  if (selection?.type === 'Caret' || selection?.type === 'None') return {};
  if (selection?.rangeCount <= 0) return {};
  const range: any = selection?.getRangeAt(0);
  const { startContainer, endContainer, commonAncestorContainer } = range;
  // 如果选中范围的根节点是math节点，那么就选中整个math的父节点 也就是span标签
  if (commonAncestorContainer?.nodeName === 'math') {
    const mathRange = document.createRange();
    mathRange.selectNode(commonAncestorContainer.parentNode);

    window.getSelection()?.removeAllRanges();
    // @ts-ignore
    window?.getSelection()?.addRange(mathRange);
  } else {
    // 选中的内容不全是在math标签内部 || 普通的文本选择
    const endRootSpan: any = getSpanPosNode(endContainer);
    const startRootSpan: any = getSpanPosNode(startContainer);

    // 如果起始节点或结束节点有公式时 选中起始节点和结束节点之间的所有内容
    if (
      endRootSpan?.dataset?.originLatex ||
      startRootSpan?.dataset?.originLatex
    ) {
      const r = document.createRange();
      r.setStart(startRootSpan, 0);
      r.setEnd(endRootSpan, 1);
      // @ts-ignore
      window.getSelection().removeAllRanges();
      // @ts-ignore
      window.getSelection().addRange(r);
    }
  }
};

// 获取length长度的随机数
export const generateRandomDigits = (length: number): string => {
  let result = '';
  for (let i = 0; i < length; i += 1) {
    const randomDigit = Math.floor(Math.random() * 10);
    result += randomDigit.toString();
  }

  return result;
};

// 处理统计数据初始化函数
export const initIalize = (type: string): any => {
  let dataScoreStat = {};
  if (type === 'RandomScoring') {
    dataScoreStat = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0
    };
  } else if (type === 'OrderlyScoring') {
    dataScoreStat = {
      0: 0,
      1: 0,
      2: 0
    };
  } else {
    dataScoreStat = 0;
  }
  return dataScoreStat;
};

// rm跳过、提交、合格不合格等数据回到顶端
export const rmScrollTo = (type: string): any => {
  let item = '';
  if (type === 'ConversationSort') {
    item = 'mark-view-area';
  } else if (
    type === 'OrderlyScoringCustomize' ||
    type === 'OrderlyScoringCustomize' ||
    type === 'OrderlyScoringCustomize'
  ) {
    item = 'main-right-area';
  } else {
    return;
  }
  const bHeight = document.getElementsByClassName(item)[0];
  if (bHeight) bHeight.scrollTo(0, 0);
};

// 自定义质检验收流程的状态
export const customStatus = {
  isBatchId_isSelf: 0,
  isBatchId_noSelf: 1,
  noBatchId: 2,
  noModify: 3,
  allNo: 4
};

export const customStatus2 = {
  all: 1, // 所有按钮都存在
  audit: 2, // 没有查询 上一条 下一条按钮
  allNo: 3 // 所有按钮都不存在
};

// 获取 url 的传参
export const useUrlParams = (): Record<string, any> => {
  const search = useLocation().search;
  return useMemo(() => {
    return qs.parse(search.slice(1));
  }, [search]);
};

// 判断是否是sft、rm模版的去质检、去验收和标注管理员
// export const useSftRmFlow = () => {
//   const searchParams = useUrlParams();
//   const { stageType } = useParams<{
//     stageType: string,
//     markType: string,
//   }>();
//   const pathname = useLocation().pathname;
//   const roleCode: string = stores.user && stores.user.roleCode;
//   return useMemo(() => {
//     const pathName = [
//       'other-pre-view/conversation-rewrite',
//       'annotation-detail/inspect-conversation-rewrite',
//       'other-pre-view/conversation-sort',
//       'annotation-detail/inspect-conversation-sort',
//       'other-pre-view/multiRound-online',
//       'annotation-detail/inspect-multiRound-online',
//     ];
//     // ?dataId=1&batchId=1
//     const batch = [
//       searchParams.status === undefined,
//     ].reduce((pre, cur) => pre && cur);
//     const markPreview = [
//       searchParams.dataId != undefined,
//       searchParams.batchId != undefined,
//     ].reduce((pre, cur) => pre || cur);
//     const newFlow = [
//       // SFT 管理员 去审核
//       pathname.includes(pathName[0]) && (stageType === 'Manager' && roleCode === 'GROUP_OWNER') && markPreview,
//       // RM 管理员 去审核
//       pathname.includes(pathName[2]) && (stageType === 'Manager' && roleCode === 'GROUP_OWNER') && markPreview,
//       // SFT 线上多轮对话重写
//       pathname.includes(pathName[4]) && (stageType === 'Manager' && roleCode === 'GROUP_OWNER') && markPreview,
//       // SFT 去质检
//       pathname.includes(pathName[1]) && batch,
//       // RM 去质检
//       pathname.includes(pathName[3]) && batch,
//       // SFT 线上多轮对话重写
//       pathname.includes(pathName[5]) && batch,
//       // SFT 质检查看详情
//       // pathname.includes(pathName[0]) && stageType === 'Inspect',
//       // RM 质检查看详情
//       // pathname.includes(pathName[2]) && stageType === 'Inspect',
//       // SFT 去验收
//       pathname.includes(pathName[0]) && stageType === 'Check' && batch,
//       // RM 去验收
//       pathname.includes(pathName[2]) && stageType === 'Check' && batch,
//       // SFT 线上多轮对话重写
//       pathname.includes(pathName[4]) && stageType === 'Check' && batch,
//     ];
//     return newFlow.reduce((pre, cur) => pre || cur);
//   }, [pathname, searchParams]);
// };
export const useSftRmFlow = () => {
  const { stageType, subMarkType } = useParams<{
    stageType: string;
    subMarkType: string;
  }>();
  const searchParams = useUrlParams();
  const roleCode: string = stores.user && stores.user.roleCode;
  return useMemo(() => {
    const templateSubMarkType = [
      // SFT
      'ConversationRewrite',
      'ConversationRewriteOnline',
      'ConversationRewriteAppend',
      'QuestionReviewAppend',
      'QuestionReview',
      'ConversationRewriteMultiRoundOnline',
      // RM
      'ConversationSort',
      'RandomScoring',
      'OrderlyScoring',
      'OrderlyScoringCustomize',
      // 通用化
      'CustomTemplate'
    ];
    const isSftRm = templateSubMarkType.reduce(
      (pre, cur) => pre || cur === subMarkType,
      false
    );
    // 判断角色
    // 判断 url 是否有自定义质检验收参数
    const customParams = [
      searchParams.dataId != undefined,
      searchParams.batchId != undefined,
      searchParams.singleDataId != undefined,
      searchParams.jumpDataId != undefined
    ].reduce((pre, cur) => pre || cur);
    const customFlow = [
      // SFT RM 团队管理员 去审核
      isSftRm &&
        (roleCode === 'GROUP_OWNER' || roleCode === 'GROUP_ADMIN') &&
        customParams,
      // SFT RM 自定义质检
      isSftRm && customParams,
      // SFT RM 自定义验收
      isSftRm && customParams
    ];
    return customFlow.reduce((pre, cur) => pre || cur);
  }, [stageType, subMarkType, searchParams, roleCode]);
};

// eslint-disable-next-line consistent-return
const getRadioContent = (key: string, data: any): any => {
  for (let i = 0; i < data.length; i += 1) {
    if (data[i].children) {
      const res = getRadioContent(key, data[i].children);
      if (res) return res;
    }
    if (data[i].key === key) {
      return data[i].config;
    }
  }
};

const findNodeByKey = (data: any, key = '') => {
  if (!data) return null;
  for (const node of data) {
    if (node.key === key) {
      return node;
    }
    if (node.children && node.children.length > 0) {
      const result: any = findNodeByKey(node.children, key);
      if (result) {
        return result;
      }
    }
  }
  return null; // 如果没有找到，返回 null
};

// 校验内容
const validateDrawConfigValues = (data: any) => {
  let isNull = false;
  console.log('data', data);
  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    // if (data.hasOwnProperty(key)) {
    const { opt, value } = data[key];
    const { type, isRequired, forbidNumber } = opt;

    // 检查 isRequired
    if (isRequired === 1) {
      isNull = type === 'checkbox' ? value.length === 0 : !value;
      if (isNull) {
        return isNull;
      }
    }

    // 检查 type 为 input 且 forbidNumber 为 1
    if (type === 'input' && forbidNumber === 1 && /\d/.test(value)) {
      isNull = true;
      return isNull;
    }
    // }
  }
  return isNull;
};

export const checkItemDataIsNull = (
  data: any,
  type: any,
  originData: any
): boolean => {
  if (type === TYPE_OBJ.TEXT_EDITOR) {
    const { children } = data.value[0];
    const isNullArray = children.filter((item: any) => !item.value);
    return !!isNullArray.length;
  }
  if (type === TYPE_OBJ.RADIO) {
    return !data.value;
    // const radioList = getRadioContent(data.key, originData.components);
    // if (radioList.options) {
    //   let optionsLength = true;
    //   for (let i = 0; i < radioList.length; i += 1) {
    //     if (radioList[i].value === data.value) {
    //       optionsLength = Boolean(radioList[i].options.length);
    //     }
    //   }
    //   return optionsLength ? !(data.value && data.children) : !data.value;
    // }
    // return false;
  }
  if (type === TYPE_OBJ.MULTI_SELECT) {
    const getValueData = data.value.filter((item: any) => !!item);
    return !getValueData.length;
  }
  if (type === TYPE_OBJ.IMAGE_ANNOTATION) {
    const getValueData = data.value.filter((item: any) => !!item);
    // 找到当前组件的config配置
    const findComConfig = findNodeByKey(originData.components, data.key);
    const { config } = findComConfig;
    const { attachTags = {} } = config;
    const attachTagsKeys = Object.keys(attachTags);
    const rules: any = {};
    console.log('attachTagsKeys', attachTagsKeys);

    attachTagsKeys.forEach((key) => {
      const arr = attachTags[key];
      arr.forEach((item: any, keyChildIdx: number) => {
        const { forbidNumber, options } = item;
        rules[`${key}${keyChildIdx}`] = {
          isRequired: item?.isRequired ?? 0,
          options: options ?? [],
          type: key
        };
        // 输入框特殊处理
        if (key === 'input') {
          rules[`${key}${keyChildIdx}`] = {
            ...rules[`${key}${keyChildIdx}`],
            forbidNumber: forbidNumber ?? 0,
            type: key
          };
        }
      });
    });

    console.log('rules', rules, attachTagsKeys, getValueData);

    // 如果配置列表不需要校验，只看图像标框检测结果
    if (attachTagsKeys.length === 0) {
      return !getValueData.length;
    }

    console.log('getValueData', getValueData);

    for (let i = 0; i < getValueData.length; i++) {
      const { options: opts } = getValueData[i];
      console.log('getValueData[i]', getValueData[i]);
      const isNull = validateDrawConfigValues(opts);
      console.log('options', opts);
      if (isNull) {
        console.log('isNull', isNull);
        return true;
      }
    }

    return !getValueData.length;
  }
  return false;
};

// 数据校验
export const checkDataIsNull = (data: any, originData: any): boolean => {
  let isNull = false;
  const checkTypeList = [
    TYPE_OBJ.TEXT_EDITOR,
    TYPE_OBJ.RADIO,
    TYPE_OBJ.MULTI_SELECT,
    TYPE_OBJ.IMAGE_ANNOTATION
  ];
  for (let i = 0; i < data.length; i += 1) {
    // 如果有子项，递归
    if (Array.isArray(data[i])) {
      isNull = checkDataIsNull(data[i], originData);
      if (isNull) {
        return isNull;
      }
      // eslint-disable-next-line no-continue
      continue;
    }
    // eslint-disable-next-line guard-for-in
    for (const objKey in data[i]) {
      // 子项是常规数据 校验
      const { type, isRequired } = data[i][objKey];

      if (checkTypeList.includes(type) && isRequired) {
        const res = checkItemDataIsNull(data[i][objKey], type, originData);
        if (res) {
          isNull = true;
          return isNull;
        }
      }
    }
  }
  return isNull;
};
// todo 格式化latex数据
export const formatJYYLatex = (content: string, contentAnalysisList = []) => {
  if (!content) return '';
  function htmlDecode(input: string): string {
    let doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent || '';
  }
  content = typeof content === 'number' ? String(content) : content;
  let latexContent = content.replace(/\$(\$)?([^]*?)\$(\$)?/g, (latexItem) => {
    /// \$([^$]*?)\$/g
    let pureLatex = latexItem.replace(/\$/g, '');
    // 处理$$中的html转义符号

    if (pureLatex != null) {
      pureLatex = htmlDecode(pureLatex);
    }

    // pureLatex = pureLatex
    //   .replace(/&gt;/g, '>')
    //   .replace(/&lt;/g, '<')
    //   .replace(/&nbsp;/g, '')
    //   .replace(/&hellip;/g, '.')
    //   .replace(/&amp;/g, '&')
    //   .replace(/&nbsp;/g, '')
    //   .replace(/&times;/g, '\\times')
    //   .replace(/&divide;/g, '\\div')
    //   .replace(/&asymp;/g, '\\approx')
    //   .replace(/&ne;/g, '\\neq')
    //   .replace(/&le;/g, '\\leq')
    //   .replace(/&ge;/g, '\\geq')
    //   .replace(/&infin;/g, '\\infty')
    //   .replace(/&prop;/g, '\\propto');
    try {
      let spaceLatexText: any = pureLatex.split(' ');
      spaceLatexText = spaceLatexText.join('\\text{ }\\text{ }');
      // @ts-ignore
      let data = window.MathJax.tex2mml(
        (window as any).supportSpace ? spaceLatexText : pureLatex,
        {
          display: false
        }
      );
      return data;
    } catch (error) {
      return pureLatex;
    }
  });

  return latexContent;
};

export const getImgWandH = (imgDom: any) => {
  const { width, height } = imgDom;
  return { width, height };
};

export const setMarkGroupData = (groupList: any, w: any, h: any): any => {
  if (!groupList) return;
  const list =
    typeof groupList === 'string' ? JSON.parse(groupList) : groupList;
  const selfW = w / 2;
  const selfH = h / 2;
  const newGroupList = list.map((group: any) => {
    const { markGraph } = group;
    const { points, originPoints, imgW, imgH } = markGraph;
    const newPoints = points.map((point: any) => {
      const { x, y } = point;
      const newX = x < 0 ? -1 * selfW + x : x - selfW;
      const newY = y > selfH ? (y - selfH) * -1 : selfH - y;
      return {
        // x: newX,
        // y: newY,

        x: x - selfW,
        y: -y + selfH
      };
    });
    return {
      ...group,
      markGraph: {
        ...markGraph,
        // 保存原始点位
        originPoints: originPoints || points,
        points: newPoints,
        imgW: imgW || w,
        imgH: imgH || h
      }
    };
  });
  // eslint-disable-next-line consistent-return
  return newGroupList;
};

export const getSourceRemark = (params: any) => {
  if (!params) return {};
  return typeof params === 'string' ? JSON.parse(params) : params;
};

export const setSourceRemark = (params: any) => {
  return typeof params === 'string' ? params : JSON.stringify(params);
};

export const rebackPoints = (
  points: any,
  imgW: any,
  imgH: any,
  isCustom: any,
  isArray = false
) => {
  const w = imgW / 2;
  const h = imgH / 2;
  const newPoints = points.map((point: any) => {
    const { x, y } = point;
    if (isCustom) {
      const newY = y > 0 ? h - y : y * -1 + h;
      // const res = isArray ? [x + w, newY] : { x: x + w, y: newY };
      const res = isArray ? [x + w, h - y] : { x: x + w, y: h - y };
      return res;
    }
    // const res = isArray ? [x + w, y + h] : { x: x + w, y: y + h };
    const res = isArray ? [x + w, h - y] : { x: x + w, y: h - y };
    return res;
  });
  return newPoints;
};

// 获取仿真数据首次预设的点位
export const getPreCacheOriginPoints = (data: any) => {
  let pointData = typeof data === 'string' ? JSON.parse(data) : data;
  if (pointData.length <= 0) return [];
  return pointData[0].markGraph.points;
};

export const sortPoints = (pointArray: any) => {
  let points = _.cloneDeep(pointArray);
  points.sort((a: any, b: any) => a.x - b.x);
  // let sortMinXPoints = points.sort((a: any, b: any) => a.x - b.x);
  const leftBottomPoint = points[0].y < points[1].y ? points[0] : points[1];
  const leftBottomPointIdx = pointArray.findIndex(
    (item: any) => leftBottomPoint.x === item.x && leftBottomPoint.y === item.y
  );

  const preIdx =
    leftBottomPointIdx - 1 < 0 ? pointArray.length - 1 : leftBottomPointIdx - 1;
  const nextIdx =
    leftBottomPointIdx + 1 > pointArray.length - 1 ? 0 : leftBottomPointIdx + 1;
  const leftTopPoint =
    pointArray[preIdx].y < pointArray[nextIdx].y
      ? pointArray[nextIdx]
      : pointArray[preIdx];
  const leftTopPointIdx = pointArray.findIndex(
    (item: any) => leftTopPoint.x === item.x && leftTopPoint.y === item.y
  );

  let resArray = _.cloneDeep(pointArray);

  if (leftBottomPointIdx === leftTopPointIdx + 1) {
    // eslint-disable-next-line max-len
    return resArray
      .slice(leftTopPointIdx, leftTopPointIdx + 1)
      .concat(
        resArray.slice(0, leftTopPointIdx).reverse(),
        resArray.slice(leftTopPointIdx + 1).reverse()
      );
  }
  return resArray
    .slice(leftTopPointIdx)
    .concat(resArray.slice(0, leftTopPointIdx));
};
// 获取当前组件所在标注区的其他静态组件名称和动态组件
export const getTextComparisonList = (data: any, key: string): any[] => {
  const result: string[] = [];
  let markAreaIndex = 0; // 寻找当前标注区的index
  const getMarkIndex = (list: any, cKey: string) => {
    if (list.children) {
      for (let i = 0; i < list.children.length; i += 1) {
        if (getMarkIndex(list.children[i], cKey)) return true;
      }
    }
    return list.key === cKey;
  };
  const getAllName = (list: any, cKey: string, res: any) => {
    if (list.children) {
      getAllName(list.children, cKey, res);
    } else {
      if (Array.isArray(list)) {
        for (let i = 0; i < list.length; i += 1) {
          if (list[i].children) {
            getAllName(list[i].children, cKey, res);
          } else {
            getAllName(list[i], cKey, res);
          }
        }
      } else {
        if (
          (list.type === 'staticShow' || list.type === 'dynamicShow') &&
          list.key !== cKey
        ) {
          res.push({
            value: JSON.stringify({
              key: list.key,
              jsonPath: list.config.jsonPath
            }),
            label: list.config.showName || '组件暂未定义名称'
          });
        }
      }
    }
    return res;
  };
  for (let i = 0; i < data.length; i += 1) {
    if (getMarkIndex(data[i], key)) markAreaIndex = i;
  }
  data[markAreaIndex].children.map((item: any) => {
    if (getAllName(item, key, []).length > 0) {
      result.push(getAllName(item, key, []));
    }
  });
  return result.flat(Infinity);
};
// 返回颜色列表
export const getColorList = () => {
  return [
    '#EDECEC',
    '#F2F4F3',
    '#E7EBEE',
    '#F5DDDB',
    '#F8EBDB',
    '#FCF9EA',
    '#E8F6DC',
    '#DFF4F4',
    '#D3D6F5',
    '#F5DEEB',
    '#DED9D6',
    '#D9D9D9',
    '#E0E0E2',
    '#ECBBB7',
    '#F3D6B9',
    '#F3ECC5',
    '#D1ECB8',
    '#C0E7EB',
    '#A5AEED',
    '#EBBEDB',
    '#BDB4AE',
    '#BFBFBF',
    '#b6b5b5',
    '#E19A96',
    '#EBC397',
    '#EEE3AB',
    '#B9E098',
    '#A4DEDF',
    '#7C83E5',
    '#E59EC5',
    '#9C8C88',
    '#A6A6A7',
    '#939393',
    '#DD7975',
    '#E6B074',
    '#E6D77F',
    '#A3D976',
    '#89D4D6',
    '#6279E0',
    '#DF7CB5',
    '#b0665f',
    '#7F7F7F',
    '#767676',
    '#964038',
    '#A06C3B',
    '#A39440',
    '#648F3E',
    '#508C8F',
    '#586acb',
    '#b46993',
    '#efe8e8',
    '#818B98',
    '#5f5f5f',
    '#D65953',
    '#E19B55',
    '#DBC54F',
    '#90D05B',
    '#6FC8CC',
    '#4E68E3',
    '#D75EA2'
  ];
};

export const hexToRgba = (hex: string, alpha = 1) => {
  if (!hex) return 'rgba(0,0,0,1)';
  // 如果是 rgba 颜色代码则直接返回
  if (hex.startsWith('rgba')) return hex;
  // 移除十六进制颜色代码中的 '#' 符号
  hex = hex.replace('#', '');

  // 解析红色、绿色和蓝色分量
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // 返回 rgba 颜色字符串
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

interface roleInfoParams {
  nickname: string;
  jobNum?: string;
  phone?: string | number;
}
// 规范人员信息展示
const formatPersonInfo = (item: roleInfoParams): string => {
  if (!item.nickname) {
    return '-';
  }
  let roleInfo = item.nickname; // 初始化为昵称

  if (item.jobNum) {
    roleInfo += `-${item.jobNum}`; // 如果 jobNum 存在，添加到 roleInfo
  }

  if (item.phone) {
    roleInfo += `-${item.phone}`; // 如果 phone 存在，添加到 roleInfo
  }

  return roleInfo;
};

//  get cookie
export const getCookie = (name = ''): any => {
  if (!name) return '';
  const cookies = document.cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};
// set cookie
export const setCookie = (
  name = '',
  value = '',
  days = 0,
  path = '/',
  domain = ''
): any => {
  if (!name) return;
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  // eslint-disable-next-line prefer-template
  const expires = 'expires=' + date.toUTCString();
  // eslint-disable-next-line prefer-template
  let cookieString = name + '=' + value + ';' + expires + ';path=' + path;
  if (domain) {
    // eslint-disable-next-line prefer-template
    cookieString += ';domain=' + domain;
  }
  document.cookie = cookieString;
};

// 删除cookie
export const delCookie = (name = '', path = '/', domain = ''): any => {
  if (!name) return;
  let cookieString =
    name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=' + path;
  if (domain) {
    cookieString += '; domain=' + domain;
  }
  document.cookie = cookieString;
};

export default {
  markTypeSwitch,
  downloadJSONFile,
  downloadFileByUrl,
  downloadPdfByUrl,
  downloadDataSetFile,
  showStatus,
  setQualifiedRate,
  messageInfo,
  getPlatformName,
  getHost,
  getProtocol,
  getWSUrl,
  verifyAddress,
  judgeAttrIsInRulesAndIsTrue,
  compareConfigItems,
  dealNum,
  findMarkInfoById,
  showAttr,
  resetTransform,
  deleteHelperFieldOfMarkAttr,
  formatSeconds,
  setTaskNameToSession,
  getTaskNameFromSession,
  getTaskNameFromLocal,
  sumUnCuttedDuration,
  compareStart,
  handleIframePlay,
  getMachineAndBrowserInfo,
  Cos,
  Tracking,
  getGptSelectionValue,
  commonCopyText,
  addCopyCallback,
  getRootNode,
  generateRandomDigits,
  initIalize,
  rmScrollTo,
  formatPersonInfo,
  getTextComparisonList,
  getColorList
};
