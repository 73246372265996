import { observable, action } from 'mobx';
import { MarkLabelType } from '../../annotation/data/interface';

interface MarkResultType{
  id: string;
  showId: string;
}

interface AudioABXMarkDataType{
  markResult: MarkResultType | unknown;
  markLabel: MarkLabelType | unknown;
}

export default class AudioABXStore {
  @observable markData: AudioABXMarkDataType = {
    markResult: {},
    markLabel: {},
  };

  @action
  addAudioABX(markData: AudioABXMarkDataType): void {
    this.markData = { ...markData };
  }

  @action
  updateAudioABX(markData: AudioABXMarkDataType): void {
    this.markData = { ...markData };
  }

  @action
  clearAudioABX(): void {
    this.markData = {
      markResult: {},
      markLabel: {},
    };
  }
}
