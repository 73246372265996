import { JSON_PATH_REGEX } from './regex';

const typeName = '静态文本展示组件';

export const STATIC_SHOW_COMPONENT_SCHEMA = {
  type: 'object',
  default: {},
  title: '静态文本展示组件',
  required: [
    'jsonPath',
    'showPath',
    'supportPreview',
    'isFormatShowText',
    'showQuestionSearch',
    'fontSize',
    'showName',
    'isShowName'
  ],
  properties: {
    jsonPath: {
      type: 'string',
      default: '',
      title: '输入路径',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【输入路径】内容错误，请修正`
      }
    },
    showPath: {
      type: 'number',
      default: 0,
      title: '是否展示入参路径',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否展示入参路径】内容错误，请修正`
      }
    },
    supportPreview: {
      type: 'number',
      default: 1,
      title: '是否支持预览',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否支持预览】内容错误，请修正`
      }
    },
    showName: {
      type: 'string',
      default: '',
      title: '组件名称',
      errorMessage: `${typeName}, 组件名称内容不符合要求`
    },
    isShowName: {
      type: 'number',
      default: 1,
      title: '是否展示组件名称',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否展示组件名称】内容错误，请修正`
      }
    },
    isFormatShowText: {
      type: 'number',
      default: 1,
      title: '格式渲染',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【格式渲染】内容错误，请修正`
      }
    },
    isShowCopy: {
      type: 'number',
      default: 1,
      title: '一键复制',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【一键复制】内容错误，请修正`
      }
    },
    showQuestionSearch: {
      type: 'number',
      title: '题目查询'
    },
    fontSize: {
      title: '字号',
      type: 'number',
      default: 14,
      minimum: 12,
      maximum: 24,
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【字号】内容错误，请修正`
      }
    }
  }
};

export default {};
