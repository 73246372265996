import { observable, action } from 'mobx';
import { VideoMarkDataType } from '../../annotation/data/interface';

export default class VideoMarkStore {
  @observable markData: VideoMarkDataType = {
    markLabel: {},
    markAttrList: []
  };

  @action
  addVideoMarkData(markData: VideoMarkDataType): void {
    this.markData = { ...markData };
  }

  @action
  updateVideoMarkData(markData: VideoMarkDataType): void {
    this.markData = { ...markData };
  }

  @action
  clearVideoMarkData(): void {
    this.markData = {
      markLabel: {},
      markAttrList: []
    };
  }
}
