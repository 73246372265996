import { observable, action } from 'mobx';
import { ImageGlobalMarkDataType } from '../../annotation/data/interface';

export default class ImageGlobalStore {
  @observable markData: ImageGlobalMarkDataType = {
    markLabel: {},
    markAttrList: [],
  };

  @action
  addImageGlobal(markData: ImageGlobalMarkDataType): void {
    this.markData = { ...markData };
  }

  @action
  updateImageGlobal(markData: ImageGlobalMarkDataType): void {
    this.markData = { ...markData };
  }

  @action
  clearImageGlobal(): void {
    this.markData = {
      markLabel: {},
      markAttrList: [],
    };
  }
}
