let baseURL = '';
if (process.env.NODE_ENV === 'development') {
  baseURL = '/api';
}

let platform = '';
switch (window.location.host) {
  case 'label.vegas.facethink.com':
  case 'label.vegas.100tal.com':
  case 'label.test.datasmarker.com':
  case 'label.datasmarker.com':
  case 'label-pre.datasmarker.com':
  case 'label-pre.vegas.100tal.com':
  case 'label-datasmarker.facethink.com':
    platform = 'MARK';
    break;
  case 'admin.vegas.facethink.com':
  case 'admin.vegas.100tal.com':
  case 'admin.test.datasmarker.com':
  case 'admin.datasmarker.com':
  case 'admin-pre.datasmarker.com':
  case 'admin-pre.vegas.100tal.com':
    platform = 'MANAGER';
    break;
  case 'client.vegas.facethink.com':
  case 'client.vegas.100tal.com':
  case 'client.test.datasmarker.com':
  case 'client.datasmarker.com':
  case 'client-pre.datasmarker.com':
  case 'client-pre.vegas.100tal.com':
    platform = 'CUSTOMER';
    break;
  default:
    break;
}
if (!platform) {
  platform = process.env.REACT_APP_PLATFORM || 'MARK';
}

const host = window.location.host;
let env = '';
if (host.match(/vegas.100tal.com/gi)) {
  env = 'PRODUCTION';
} else {
  env = 'DEVELOPMENT';
}
// 测试接口已经下了，后续修改用线上，
// const env = 'PRODUCTION';
export { platform, baseURL, env };
