import { AxiosPromise } from 'axios';
import { sha256 } from 'js-sha256';
import request from '../../common/request';

const baseUrl = '/rbac';

export interface RegisterParamsType {
  captcha: string;
  groupCode?: string;
  groupName?: string;
  password: string;
  phone: string;
  roleType: string;
  smsCode: string;
  nickName: string;
}

export function register(params: RegisterParamsType): AxiosPromise {
  params.password = sha256(params.password);
  return request(`${baseUrl}/register`, {
    method: 'POST',
    data: params
  });
}

export interface GetSMSCodeParamsType {
  captcha: string;
  phone: string;
  smsType?: string;
  source?: string;
}

export function getSMSCode(params: GetSMSCodeParamsType): AxiosPromise {
  return request(`${baseUrl}/sendSms`, {
    method: 'POST',
    data: params
  });
}

export interface modifyPasswordParamsType {
  captcha: string;
  phone: string;
  smsCode: string;
  password: string;
}

export function modifyPassword(params: modifyPasswordParamsType): AxiosPromise {
  params.password = sha256(params.password);
  return request(`${baseUrl}/modifyPassword`, {
    method: 'POST',
    data: params
  });
}

export interface modifyPhoneParamsType {
  oldPhone: string;
  captcha: string;
  newPhone: string;
  smsCode: string;
  password: string;
}
export function modifyPhone(params: modifyPhoneParamsType): AxiosPromise {
  params.password = sha256(params.password);
  return request(`${baseUrl}/modifyPhone`, {
    method: 'POST',
    data: params
  });
}
// 获取切换平台的token
export function getUserInfo(): AxiosPromise {
  return request(`${baseUrl}/getUserInfo`, {
    method: 'get'
  });
}
export interface CheckoutPlatformParamsType {
  groupId: number;
  roleId: number;
  isCheck: number;
}
export function checkoutPlatform(
  value: CheckoutPlatformParamsType
): AxiosPromise {
  return request(`${baseUrl}/checkoutPlatform`, {
    method: 'POST',
    data: value
  });
}
