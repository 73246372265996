/* eslint-disable quotes */
import { SHOW_NAME_REGEX, OUT_PUT_REGEX } from './regex';

const typeName = '图像标注';

export const IMG_ANNOTATION_COMPONENT_SCHEMA = {
  type: 'object',
  default: {},
  title: '图像标注',
  required: [
    'jsonPath',
    'showName',
    'outputWord',
    'isRequired',
    'MarkToolList',
    'isCount',
    'isPreViewJsonPath',
    'isShowName'
  ],
  properties: {
    jsonPath: {
      type: 'string',
      default: '',
      title: '输入路径',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【输入路径】内容错误，请修正`
      }
    },
    showPath: {
      type: 'number',
      default: 0,
      title: '是否展示入参路径',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否展示入参路径】内容错误，请修正`
      }
    },
    showName: {
      type: 'string',
      title: '组件名称',
      pattern: SHOW_NAME_REGEX,
      errorMessage: `${typeName}, 组件名称内容不符合要求`
    },
    outputWord: {
      type: 'string',
      default: '',
      title: '输出字段',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【输出字段】内容错误，请修正`
      }
    },
    isRequired: {
      type: 'number',
      default: 1,
      title: '是否必填',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否必填】内容错误，请修正`
      }
    },
    isShowName: {
      type: 'number',
      default: 1,
      title: '是否展示组件名称',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否展示组件名称】内容错误，请修正`
      }
    },
    MarkToolList: {
      type: 'array',
      title: '标注工具',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【标注工具】内容错误，请修正`
      }
    },
  }
};

export default {};
