export const JSON_PATH_REGEX = '/^$.([a-zA-Z0-9[].]+)$/';

// 展示名称正则
// eslint-disable-next-line quotes
export const SHOW_NAME_REGEX = `^$|^[A-Z0-9a-z\\u4e00-\\u9fa5，,。\\.？?！!：:；;'""“”‘’（）\\(\\)\\[\\]【】<>_《》—…、]+$`;

// 导出字段正则
export const OUT_PUT_REGEX = '^[A-Za-z_][A-Za-z0-9_]*$';

export default {};
