// import { JSON_PATH_REGEX } from './regex';

export const ROW_AREA_COMPONENT_SCHEMA = {
  type: 'object',
  default: {},
  title: 'Row'
  //   properties: {
  //     jsonPath: {
  //       type: 'string',
  //       default: '',
  //       title: 'jsonPath',
  //       pattern: '^\\$\\.\\([a-zA-Z0-9\\[\\]]+\\)$'
  //     },
  //     showPath: {
  //       type: 'number',
  //       default: 0,
  //       title: '是否展示入参路径'
  //     },
  //     showName: {
  //       type: 'string',
  //       default: '',
  //       title: '组件标题'
  //     },
  //     isFormatShowText: {
  //       type: 'number',
  //       default: 1,
  //       title: '格式渲染'
  //     },
  //     fontSize: {
  //       title: '字号',
  //       type: 'number',
  //       default: 14,
  //       minimum: 12,
  //       maximum: 24
  //     }
  //   }
};

export default {};
