export default {};

// 后缀对应的数字
export const SUFFIX_NUM = {
  IMAGES: 1,
  VIDEO: 2,
  AUDIO: 3,
  TEXT: 4,
  DOCX: 5
};

// 后缀名称对应的数字
export const SUFFIX_NAME_TO_NUM = {
  png: SUFFIX_NUM.IMAGES,
  jpg: SUFFIX_NUM.IMAGES,
  jpeg: SUFFIX_NUM.IMAGES,
  mp4: SUFFIX_NUM.VIDEO,
  mp3: SUFFIX_NUM.AUDIO,
  m3u8: SUFFIX_NUM.VIDEO,
  wav: SUFFIX_NUM.AUDIO,
  txt: SUFFIX_NUM.TEXT,
  json: SUFFIX_NUM.TEXT,
  docx: SUFFIX_NUM.DOCX
};
