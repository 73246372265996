import { observable, action, toJS } from 'mobx';
import utils from '../../common/utils';
import { TemplateInfo } from '../../annotation/data/interface';

export default class ImageDrawBoxStore {
  @observable markList: TemplateInfo[] = [];

  @observable selectedMarkData: TemplateInfo = {
    markGraph: [],
    markLabel: {},
    markAttrList: [],
  };

  @action
  addImageDrawBox(markList: TemplateInfo[]): void {
    this.markList = markList;
  }

  @action
  updateImageDrawBox(markList: TemplateInfo[]): void {
    this.markList = markList;
  }

  @action
  clearImageDrawBox(): void {
    this.markList = [];
  }

  @action
  deleteImageDrawBox(markList: TemplateInfo[]): void {
    this.markList = markList;
  }

  @action
  addSelectedMarkData(selectedMarkData: TemplateInfo): void {
    this.selectedMarkData = { ...selectedMarkData };
  }

  @action
  updateSelectedMarkData(selectedMarkData: TemplateInfo): void {
    this.selectedMarkData = { ...selectedMarkData };
    const newMarkList = toJS(this.markList);
    const index = utils.findMarkInfoById(selectedMarkData.markGraph.id, newMarkList);
    if (index > -1) {
      newMarkList[index] = { ...selectedMarkData };
    }
    this.updateImageDrawBox([...newMarkList]);
  }

  @action
  clearSelectedMarkData(): void {
    this.selectedMarkData = {
      markGraph: [],
      markLabel: {},
      markAttrList: [],
    };
  }
}
